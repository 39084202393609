import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import logo from '../../assets/styles/logo.png';
import * as S from './Navbar.styles';

const Navbar = () => {
  const { isAuthenticated } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <S.NavContainer>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <S.BrandContainer>
          <S.Brand>PrepForJob</S.Brand>
          <S.Logo src={logo} alt="Logo" />
        </S.BrandContainer>
      </Link>
      {isAuthenticated && (
        <>
          <S.MenuToggle onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </S.MenuToggle>
          <S.NavLinks isOpen={isMenuOpen}>
            <S.StyledLink to="/creditbalance" onClick={toggleMenu}>Credits</S.StyledLink>
            <S.StyledLink to="/dashboard" onClick={toggleMenu}>Dashboard</S.StyledLink>
          </S.NavLinks>
        </>
      )}
    </S.NavContainer>
  );
};

export default Navbar;


