import React from 'react';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed; // Changed to fixed to cover the entire screen
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); // Adding a dark backdrop for contrast
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 450px;
  text-align: center; // Centering the text for a more engaging appearance
`;

const CloseButton = styled.button`
  background: #f44336; // A more vibrant color for the close button
  border: none;
  border-radius: 50%;
  color: white; // White text for contrast
  font-weight: bold;
  position: absolute;
  right: -10px; // Positioning outside the modal for a modern look
  top: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PromotionHeader = styled.h2`
  font-size: 24px;
  color: #007bff; // Using a more optimistic color
  margin-bottom: 20px;
`;

const PromotionMessage = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.5;
`;

// Renaming and redefining the component to match its new purpose
const PromotionModal = ({ header = "Unlock Your Full Potential", promotionMessage, onClose }) => {
  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <PromotionHeader>{header}</PromotionHeader>
        <PromotionMessage>{promotionMessage}</PromotionMessage>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default PromotionModal;
