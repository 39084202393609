// ProtectedRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom'; 

const ProtectedRoute = ({ children }) => {
 
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  
  // If the user is authenticated, return the children (the component to render)
  if (isAuthenticated) {
    return children;
  }

  // If the user is not authenticated, redirect to the login page
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;


