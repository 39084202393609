import React from 'react';
import {
  PolicyContainer,
  PolicyHeading,
  LastUpdated,
  Section,
  Subheading,
  Content,
  ExternalLink,
} from './PrivacyPolicy.styles';

const PrivacyPolicy = () => {


  // useEffect(() => {
  //   localStorage.removeItem('retrieve');
  // }, []); // Runs once on component mount  

  return (
    <PolicyContainer>
      <PolicyHeading>Privacy Policy</PolicyHeading>
      <LastUpdated>Last updated: January 1, 2024</LastUpdated>

      <Section>
        <Subheading>Introduction</Subheading>
        <Content>
          Welcome to PrepForJob.io. Our commitment to your data privacy and security is unwavering. Leveraging OpenAI's advanced AI technologies, and partnering with trusted service providers like Google and Stripe, we ensure the safeguarding of your personal and financial information across all interactions with our platform. Our services, designed to enhance your career prospects, are built on the foundation of trust and integrity. We collect only what is necessary, ensuring your privacy while enabling us to deliver personalized, efficient services. You retain full control over your data, with the freedom to request updates, erasure, or modifications at any time.
        </Content>
      </Section>

      <Section>
        <Subheading>Data Processing by OpenAI</Subheading>
        <Content>
          At the core of our AI-powered services is the sophisticated technology provided by OpenAI. Utilizing OpenAI's API, we harness the power of advanced AI to process your data, offering unparalleled accuracy and innovation in resume building, cover letter crafting, and mock interview simulations. OpenAI's commitment to privacy and security complements our own, ensuring that your data is not only processed with cutting-edge efficiency but also protected with the utmost care. OpenAI's robust privacy framework is designed to safeguard personal information, making it a reliable partner in delivering our services. For comprehensive details on the privacy measures and data handling practices employed by OpenAI, we encourage you to review <ExternalLink href="https://openai.com/policies/privacy-policy" target="_blank">OpenAI's Privacy Policy</ExternalLink>.
        </Content>
      </Section>

      <Section>
        <Subheading>Password and Account Security</Subheading>
        <Content>
          Your password and account security are paramount. We implement state-of-the-art hashing techniques to secure sensitive data, and for social logins, rely on Google's robust authentication mechanisms to protect your account, ensuring no direct access to or storage of your password.
        </Content>
      </Section>

      <Section>
        <Subheading>Payment Information Security</Subheading>
        <Content>
          Our partnership with Stripe, a PCI DSS compliant service, guarantees that your payment details are processed securely, with no direct handling or storage on our part. For more information, review <ExternalLink href="https://stripe.com/en-ca/privacy" target="_blank">Stripe's Privacy Policy</ExternalLink>.
        </Content>
      </Section>

      <Section>
        <Subheading>Document and Data Retention</Subheading>
        <Content>
          Our commitment to your privacy is reflected in our document and data retention policies. We are explicit about what we do not retain: resumes, cover letters, mock interviews, or Q&A conversations are not stored beyond the active session. This ensures that your personal achievements and information are protected and not held longer than necessary. What we save are the latest evaluation reports and action plans, which contain minimal personal information required for personalizing your experience and supporting your career growth. These documents are kept only as long as they serve this purpose, emphasizing our dedication to maximizing your privacy and security. Our approach is meticulously designed to safeguard your information, aligning with the highest standards of data privacy.
        </Content>
      </Section>

      <Section>
        <Subheading>Contact Information</Subheading>
        <Content>
          Your contact details, provided during support inquiries or user registration, enable us to communicate effectively, addressing your questions and keeping you informed. We value your engagement and protect your information with the utmost care.
        </Content>
      </Section>

      <Section>
        <Subheading>Sensitive Information</Subheading>
        <Content>
          Our platform strictly adheres to data protection laws and principles, ensuring we do not solicit, collect, or process information categorized as sensitive. This includes, but is not limited to, data pertaining to racial or ethnic origins, political opinions, religious or philosophical beliefs, health information, sexual orientation, and other similar categories protected under privacy laws.
          We urge our users to exclude sensitive personal information from their resumes, cover letters, and any other documents or interactions facilitated by our platform. Our services are designed with a focus on enhancing your professional attributes and career opportunities, without the need to access or utilize sensitive personal data.
          In instances where sensitive information is inadvertently submitted, we guarantee its non-use and confidentiality. Protecting the privacy and security of all user data remains our utmost priority.
          For inquiries or additional information regarding our data management practices, please refer to our <a href="/about-us#contact-us">Contact Us section on the About Us page</a>. We are committed to transparency and are readily available to address any of your concerns.
        </Content>
      </Section>

      <Section>
        <Subheading>Cookies</Subheading>
        <Content>
          Cookies are small text files placed on your device that enhance your experience on our website. They are crucial for our website's correct functioning, allowing us to improve user experience and tailor our services.
          <br /><br />
          Essential Security Cookies: We employ essential cookies to ensure the security and integrity of your user session on our website. These cookies are necessary for authenticating user sessions securely and are not used for tracking purposes or collecting personal information beyond what is required for these security purposes.
          <br /><br />
          Currently, our cookie use is focused on these essential security functions. However, we may in the future consider using cookies for:
          <br /><br />
          Site Functionality: Essential cookies for website operation, enabling navigation and feature use.
          <br /><br />
          Analytics: To understand website usage better and improve our services, based on collective user interactions.
          <br /><br />
          Marketing: For delivering more relevant advertising based on user interests and measuring effectiveness.
          <br /><br />
          We commit to asking for your consent before any non-essential cookies are placed on your device, offering you control over their use. Essential security cookies are implemented automatically as they are critical for the website's secure operation and your data protection.
          <br /><br />
          We are dedicated to transparency about our data collection practices and ensuring your privacy. For more details on how we use cookies, including managing preferences, please refer to our updated privacy policy.
          <br /><br />
          Should you have any questions or concerns regarding our use of cookies or other privacy matters, please refer to our <a href="/about-us#contact-us">Contact Us section on the About Us page</a>.
        </Content>
      </Section>

      <Section>
        <Subheading>How Do We Protect Your Personal Information?</Subheading>
        <Content>
          We employ a comprehensive security strategy to protect your personal information, integrating advanced technological solutions and rigorous procedural safeguards. This includes encryption of data in transit and at rest, regular security assessments to identify and mitigate potential vulnerabilities, and strict access controls ensuring only authorized personnel can access your information. Our commitment extends to ongoing staff training in data protection best practices and collaboration with technology partners who share our dedication to security. These measures, collectively, ensure the confidentiality, integrity, and availability of your personal data.
        </Content>
      </Section>

      <Section>
        <Subheading>Your Legal Rights and Data Management</Subheading>
        <Content>
          At PrepForJob.io, we recognize and respect your rights over your personal data. You have the right to access, correct, update, or request the deletion of your personal information at any time. To exercise any of these rights, please refer to our <a href="/about-us#contact-us">Contact Us section on the About Us page</a>. We are committed to facilitating your requests in accordance with applicable laws and our data protection obligations.
          <br /><br />
          When requesting data deletion, please be aware that we may retain certain records, such as transaction history, to comply with our legal obligations, resolve disputes, and enforce our agreements. Our policy ensures that your data is managed responsibly and securely, reflecting our dedication to your privacy and the protection of your information.
        </Content>
      </Section>

      <Section>
        <Subheading>Can This Privacy Policy Change?</Subheading>
        <Content>
          Amendments to our policy will be communicated, ensuring you remain informed. For inquiries, our team is available via our <a href="/about-us#contact-us">Contact Us section on the About Us page</a>.
        </Content>
      </Section>
    </PolicyContainer>
  );
};

export default PrivacyPolicy;
