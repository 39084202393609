import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled,{ css } from 'styled-components';
import { useUser } from '../../context/UserContext';


// Define a primary color scheme
const primaryColor = '#0052cc'; // A strong, trustworthy blue
const primaryHoverColor = '#003d99'; // A darker shade for hover states
const errorColor = '#ff4136'; // A bright red for errors, ensuring visibility
const neutralColor = '#555'; // A neutral color for less prominent elements


// Shared styles for form elements

const sharedInputStyles = css`
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 2px solid #dee2e6; // Light grey border for subtlety
  border-radius: 6px;
  font-size: 16px;
  color: ${neutralColor};
  background-color: #fff;
  &:focus {
    border-color: ${primaryColor};
    box-shadow: 0 0 0 0.2rem rgba(0, 82, 204, 0.25); // Add a subtle focus shadow
    outline: none;
  }
`;

const Form = styled.form`
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f8f9fa; // A very light background to elevate the form from the page
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 600; // Bold font weight for labels to increase formality
  margin-bottom: 10px;
  display: block;
  color: ${neutralColor};
`;

const Input = styled.input`
  ${sharedInputStyles}
`;

const Button = styled.button`
  width: 100%;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s, box-shadow 0.2s;

  // Using props to differentiate button types (submit vs. cancel)
  background-color: ${props => props.variant === 'submit' ? primaryColor : neutralColor};
  &:hover {
    background-color: ${props => props.variant === 'submit' ? primaryHoverColor : '#404040'};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const buttonStyle = `
  width: 100%;
  padding: 15px 20px;
  font-size: 18px; // Larger font size for buttons
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out; // Smooth transition for hover effects
  display: block; // Ensure buttons are block level for full width
  margin-top: 15px; // Consistent margin for spacing
`;

const SubmitButton = styled.button`
  ${buttonStyle}
  background-color: #007bff; // Primary color for submission
  color: white;
  &:hover {
    background-color: #0056b3; // Darken on hover for feedback
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed; // Indicate non-interactivity
  }
`;

const ErrorMsg = styled.div`
  color: ${errorColor};
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
`;

const CardElementWrapper = styled.div`
  margin-bottom: 30px; // Adjust this value as needed for consistency
`;


const CheckoutForm = ({ credits, calculateUSD, onPaymentSuccess, BASE_URL, onClose }) => {
  console.log('BASE_URL', BASE_URL);
  const stripe = useStripe();
  const elements = useElements();
  const { dispatch,currentUser} = useUser();

  const [billingEmail, setBillingEmail] = useState('');
  const [billingName, setBillingName] = useState('');
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [error, setError] = useState('');

  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const createPaymentIntent = async () => {
    // Ensure you handle the loading state and errors appropriately
    setError(''); // Clear previous errors
    try {
      const response = await fetch(`${BASE_URL}/api/v1/create-payment-intent/`, {
        method: 'POST',
        credentials: 'include', // Ensures that cookies are sent with the request
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': currentUser.csrf_token,
          'Authorization': `Bearer ${currentUser ? currentUser.access_token : ''}`,
        },
        body: JSON.stringify({ credits })  // Send the credits to the server
      });

      const paymentIntentData = await response.json();
      if (!response.ok) throw new Error(paymentIntentData.detail || 'Failed to create payment intent');

      return paymentIntentData;
    } catch (err) {
      setError(err.message || 'Error creating payment intent');
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError('Stripe.js has not yet loaded. Please wait and try again.');
      return;
    }

    setPaymentProcessing(true);

    const paymentIntentData = await createPaymentIntent();
    if (!paymentIntentData) {
      setPaymentProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const paymentResult = await stripe.confirmCardPayment(paymentIntentData.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: billingEmail,
            name: billingName,
          },
        },
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        onPaymentSuccess();
        // Dispatch action to update currentUser with stripe_customer_id
        console.log('paymentIntentData.stripe_customer_id', paymentIntentData.stripe_customer_id);
        dispatch({
            type: 'UPDATE_STRIPE_CUSTOMER_ID',
            payload: paymentIntentData.stripe_customer_id,
          });
       
      }
    } catch (err) {
      setError(err.message || 'Error processing payment');
    } finally {
      setPaymentProcessing(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="credits">Amount to be charged</Label>
      <div>{calculateUSD(credits)} US cents</div>

      <Label htmlFor="email">Email</Label>
      <Input
        id="email"
        type="email"
        value={billingEmail}
        onChange={handleFieldChange(setBillingEmail)}
        required
        aria-required="true"
      />

      <Label htmlFor="name">Name</Label>
      <Input
        id="name"
        type="text"
        value={billingName}
        onChange={handleFieldChange(setBillingName)}
        required
        aria-required="true"
      />
      <Label htmlFor="card-element">Payment Information</Label>
      <CardElementWrapper>
        <CardElement options={{ hidePostalCode: true }} />
      </CardElementWrapper>
      <SubmitButton disabled={paymentProcessing} type="submit">
        {paymentProcessing ? 'Processing...' : 'Proceed'}
      </SubmitButton>
      <Button disabled={paymentProcessing} type="button" onClick={onClose}>Cancel</Button>
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </Form>
  );
};

export default CheckoutForm;
