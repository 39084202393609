import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useUser } from '../../context/UserContext';
import { validateUpdate } from '../../utils/formValidation';
import * as S from './UserProfilePage.styles';
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import useUpdateUser from '../../hooks/useUpdateUser';
import { getErrorMessage } from '../../utils/api';
import Spinner from '../../components/Spinner/Spinner';
import ServiceRecordModal from '../../components/ServiceRecords/ServiceRecordModal';
import { useNavigate } from 'react-router-dom';
import AccountDeleteModal from '../../components/AccountDeleteModal/AccountDeleteModal';

const UserProfilePage = React.memo(() => {
  const { currentUser } = useUser();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const { mutateAsync: updateUser, isLoading, error } = useUpdateUser(setMessage);
  const [generalError, setGeneralError] = useState('');
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isServiceRecordModalOpen, setIsServiceRecordModalOpen] = useState(false);
  const navigate = useNavigate();


  const handleOpenServiceRecordModal = () =>  setIsServiceRecordModalOpen(true);
  const handleCloseServiceRecordModal = () => setIsServiceRecordModalOpen(false);

  // Define initial form data based on currentUser
  const initialFormData = useMemo(() => ({
    username: currentUser?.username || "",
    email: currentUser?.email || "",
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }), [currentUser]);

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  // Effect hook to listen for errors from updateUser and update generalError state
  useEffect(() => {
    if (error) {
      console.log("Error detected in useEffect:", error);
      const message = getErrorMessage(error);
      setGeneralError(message);
      setShowErrorModal(true);
    }
    // Ensure to clear the error message when the component unmounts or error changes
    return () => setGeneralError('');
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateUpdate(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    
   
    try {
      await updateUser({
          userId: currentUser.id,
          updateData: formData,
          csrfToken: currentUser.csrf_token,
          accessToken: currentUser.access_token,
      });
  } catch (e) {
      console.error("Caught error:", e.message); 
  }
  };


  // Inside the UserProfilePage component before the return statement
const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);


const handleDeleteAccount = async () => {
  console.log("URL TO DELETE ACCOUNT", `${BASE_URL}/api/v1/users/delete-account`)
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users/delete-account`, {
      method: 'DELETE',
      credentials: 'include', // For cookies to be sent
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': currentUser.csrf_token,
        'Authorization': `Bearer ${currentUser.access_token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(responseData.detail || 'Unknown error');
  }
    
    navigate('/login')
  } catch (error) {
    console.error(error);
    const message = getErrorMessage(error);
    setGeneralError(message); // Set error message from account deletion failure
    // setShowErrorModal(true);
  } 
};

const openDeleteAccountModal = () => {
  setIsDeleteAccountModalOpen(true);
  setGeneralError(''); // Clear any previous error message
}

const closeDeleteAccountModal = () => setIsDeleteAccountModalOpen(false);



  const EyeOpen = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const EyeClosed = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5 2.5L21.5 21.5" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );


  if (!currentUser) {
    return <Spinner text = "Loading your profile details..."/>;
  }

  return (
    <S.UserProfileContainer>
      <S.Title>Manage Your Profile</S.Title>
      {showErrorModal && (
        <ErrorModal
          errorMessage={generalError}
          onClose={() => setShowErrorModal(false)}
        />
      )}
      <S.Form onSubmit={handleSubmit}>
        <S.Section>
          <S.SectionHeader>Personal Information</S.SectionHeader>
          <S.Label>User Name</S.Label>
          <S.InputField
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            error={errors.username}
          />
          {errors.username && <S.Error>{errors.username}</S.Error>}
          {!currentUser?.google_id && (
          <>
            <S.Label>Email</S.Label>
            <S.InputField
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
            />
            {errors.email && <S.Error>{errors.email}</S.Error>}
          </>
          )}
        </S.Section>
        {!currentUser?.google_id && (
        <S.Section>
          <S.SectionHeader>Password Management</S.SectionHeader>
          <S.Label>Current Password</S.Label>
          <S.InputWrapper>
            <S.InputField
              type={showPassword ? 'text' : 'password'}
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              error={errors.currentPassword}
            />
            <S.ToggleButton onClick={togglePasswordVisibility}>
              {showPassword ? <EyeClosed /> : <EyeOpen />}
            </S.ToggleButton>
          </S.InputWrapper>
          {errors.currentPassword && <S.Error>{errors.currentPassword}</S.Error>}
          <S.Label>New Password</S.Label>
          <S.InputWrapper>
            <S.InputField
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              error={errors.newPassword}
            />
            <S.ToggleButton onClick={togglePasswordVisibility}>
              {showPassword ? <EyeClosed /> : <EyeOpen />}
            </S.ToggleButton>
          </S.InputWrapper>
          {errors.newPassword && <S.Error>{errors.newPassword}</S.Error>}
          <S.Label>Confirm New Password</S.Label>
          <S.InputWrapper>
            <S.InputField
              type={showPassword ? 'text' : 'password'}
              name="confirmNewPassword"
              value={formData.confirmNewPassword}
              onChange={handleChange}
              error={errors.confirmNewPassword}
            />
            <S.ToggleButton onClick={togglePasswordVisibility}>
              {showPassword ? <EyeClosed /> : <EyeOpen />}
            </S.ToggleButton>
          </S.InputWrapper>
          {errors.confirmNewPassword && <S.Error>{errors.confirmNewPassword}</S.Error>}
        </S.Section>
        )}
        <S.SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? 'Updating Profile...' : 'Save Changes'}
        </S.SubmitButton>
        {message && <S.SuccessMessage>{message}</S.SuccessMessage>}
      </S.Form>
      <S.Button onClick={handleOpenServiceRecordModal}>Service Records</S.Button>
      <ServiceRecordModal
        isOpen={isServiceRecordModalOpen}
        onClose={ handleCloseServiceRecordModal}
        BASE_URL={BASE_URL}
      />
     
      <S.Button onClick={openDeleteAccountModal}>Delete Account</S.Button>
      <AccountDeleteModal
        isOpen={isDeleteAccountModalOpen}
        onClose={closeDeleteAccountModal}
        onConfirm={handleDeleteAccount} // Pass handleDeleteAccount as onConfirm
        title="Account Deletion Confirmation"
        errorMessage={generalError}
      >
        <p>This action cannot be undone. All of your data will be permanently removed. If you wish to use our service again in the future, you will need to re-register.</p>
        {/* Buttons are now part of CustomModal, no need to define them here */}
      </AccountDeleteModal>



    </S.UserProfileContainer>
  );
});

export default UserProfilePage;
