

// src/hooks/useLogoutUser.js
import { useMutation } from 'react-query';
import { logoutUser } from '../utils/api'; 
import { useUser } from '../context/UserContext';

const useLogoutUser = () => {
  const { dispatch } = useUser();

  return useMutation(
    ({accessToken, csrfToken}) => logoutUser(accessToken, csrfToken),
    {
      onSuccess: () => {
        dispatch({ type: 'SET_CURRENT_USER', payload: null });
        dispatch({ type: 'SET_USER_AUTH_SUCCESS', payload: false });
        localStorage.removeItem("isAuthenticated");
      },
      onError: (error) => {
        console.error('Logout error:', error);
        
      },
    }
  );
};

export default useLogoutUser;