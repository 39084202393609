import React from 'react';
import {
  TermsContainer,
  Heading,
  LastUpdated,
  Section,
  Subheading,
  Content,
} from './TermsOfService.styles';

const TermsOfService = () => {

  // useEffect(() => {
  //   localStorage.removeItem('retrieve');
  // }, []); // Runs once on component mount  

  return (
    <TermsContainer>
      <Heading>Terms of Service</Heading>
      <LastUpdated>Last Updated: January 1, 2024</LastUpdated>

      <Section>
        <Subheading>Acceptance of Terms</Subheading>
        <Content>
          By accessing or using the services provided by PrepForJob.io, including any software, mobile apps, and websites (collectively, the "Service"), you agree to be bound by these Terms of Service ("Terms"). These Terms govern your access to and use of the Service and any videos, text, graphics, or other content made available through the Service (collectively, "Content"). Please read these Terms carefully, and contact us if you have any questions. By accessing or using our Service, you agree to these Terms and to our Privacy Policy, incorporated herein by reference.
        </Content>
      </Section>

      <Section>
        <Subheading>Privacy and Data Protection</Subheading>
        <Content>
          Your privacy is critically important to us. PrepForJob.io is committed to aligning our use of your personal information with our Privacy Policy. We encourage you to read our Privacy Policy carefully to understand how we collect, use, protect, and handle your information. By using our Service, you consent to the data practices described in our Privacy Policy.
        </Content>
      </Section>

      <Section>
        <Subheading>Use of the Service</Subheading>
        <Content>
          The Service allows you to create, access, and manage your career-related documents, such as resumes and cover letters, utilize AI-powered tools for enhancing your job search, and access various career advancement resources. You agree to use the Service only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Service.
        </Content>
      </Section>

      <Section>
        <Subheading>Account Security</Subheading>
        <Content>
          You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. We encourage you to use "strong" passwords (passwords that use a combination of upper and lower case letters, numbers, and symbols) with your account. PrepForJob.io cannot and will not be liable for any loss or damage arising from your failure to comply with the above.
        </Content>
      </Section>

      <Section>
        <Subheading>Intellectual Property</Subheading>
        <Content>
          All rights, title, and interest in and to the Service (excluding Content provided by users) are and will remain the exclusive property of PrepForJob.io and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Nothing in these Terms gives you a right to use the PrepForJob.io name or any of the PrepForJob.io trademarks, logos, domain names, and other distinctive brand features.
        </Content>
      </Section>

      {/* <Section>
        <Subheading>Content on the Service</Subheading>
        <Content>
          You retain your rights to any Content you submit, post, or display on or through the Service. By submitting, posting, or displaying Content on or through the Service, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such Content in any and all media or distribution methods (now known or later developed).
        </Content>
      </Section> */}

      <Section>
        <Subheading>Content on the Service</Subheading>
        <Content>
          While you retain rights to any content you submit, post, or display on or through the Service, your decision to share this content grants us a limited, worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content solely for the purpose of providing and improving the Service. This license is subject to the comprehensive privacy protections outlined in our Privacy Policy, emphasizing our commitment to safeguarding your personal and sensitive information.

          Consistent with our Privacy Policy, we recognize your continuous ownership of your content and your right to manage, amend, or delete your content at any time. We are dedicated to ensuring that our use of your content aligns with your rights and privacy expectations, including adhering to any requests for content modification or removal in accordance with applicable laws and our data protection obligations.

          Our platform's use of your content will always respect the boundaries of your privacy and data rights, ensuring that your personal achievements and contributions are handled with the utmost care and discretion. For detailed information on how we manage and protect your data, including your content, please refer to our Privacy Policy. We encourage you to exercise your rights detailed therein, including updating, erasing, or modifying your content, to ensure it reflects your current preferences and consent.
        </Content>
      </Section>


      <Section>
        <Subheading>Termination</Subheading>
        <Content>
          We may terminate or suspend your access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms. Upon termination, your right to use the Service will immediately cease.
        </Content>
      </Section>

      <Section>
        <Subheading>Changes to the Terms</Subheading>
        <Content>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </Content>
      </Section>

      <Section>
        <Subheading>Governing Law</Subheading>
        <Content>
          These Terms will be governed by and interpreted in accordance with the laws of the jurisdiction in which you reside, without regard to its conflict of law provisions.
        </Content>
      </Section>

      <Section>
        <Subheading>Contact Us</Subheading>
        <Content>
          If you have any questions about these Terms, please contact us through the <a href="/about-us#contact-us">Contact Us section on the About Us page</a>.
        </Content>
      </Section>
    </TermsContainer>
  );
};

export default TermsOfService;
