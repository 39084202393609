// Footer.styles.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  width: 100%;
  padding: 8px 0; // Reduced vertical padding to make the footer shorter
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
`;

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: #333;
  text-decoration: none;
  margin: 3px 15px; // Reduced margin to bring links closer together vertically
  padding: 3px 10px;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: #0056b3;
    background-color: #d0d7de;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
`;

export const CopyrightText = styled.span`
  color: #666;
  font-size: 14px;
  margin-top: 4px; // Reduced margin to bring the copyright text closer to the links
  width: 100%;
  text-align: center;
`;


