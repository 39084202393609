import React, { useState, useEffect } from 'react';
import styled , { keyframes } from 'styled-components';
import { useUser } from '../../context/UserContext';


// Animation for modal appearance
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.5s ease-out;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  background-color: #007bff;
  color: white;
  float: right;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #0056b3;
  }
`;

const TransactionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const TransactionItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Field = styled.div`
  & > strong {
    display: block;
    color: #212529;
    margin-bottom: 5px;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px; /* Adjust the value as needed */
`;



const TransactionHistoryModal = ({ isOpen, onClose, BASE_URL }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useUser();

  useEffect(() => {
   
      
    if (!currentUser || !currentUser.access_token || !currentUser.stripe_customer_id) {
        setError('Authentication required and valid Stripe customer ID is needed.');
        return;
      }
      
    if (isOpen) {
      const fetchTransactions = async () => {
        setIsLoading(true);
        try {
          
        const response = await fetch(`${BASE_URL}/api/v1/user/${currentUser.stripe_customer_id}/transactions`, {
            credentials: 'include', // Ensures that cookies are sent with the request
            headers: {
                'Authorization': `Bearer ${currentUser.access_token}`,
                'X-CSRF-Token': currentUser.csrf_token,
                'Content-Type': 'application/json',
            },
        });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to fetch transactions');
          }
          
          const data = await response.json();
          setTransactions(data.transactions);
        } catch (err) {
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchTransactions();
    }
  }, [isOpen, BASE_URL,  currentUser]);

  // Handler for clicks on the overlay
  const handleOverlayClick = (event) => {
    // Check if the click was on the overlay itself and not its children
    if (event.target === event.currentTarget) {
      onClose(); // Close the modal
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <Title>Transaction History</Title>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
            <TransactionList>
                {transactions.map((transaction) => (
                <TransactionItem key={transaction.id}>
                    <Field>
                    <strong>Amount</strong>
                    ${transaction.amount_usd.toFixed(2)} USD
                    </Field>
                    <Field>
                    <strong>Date</strong>
                    {new Date(transaction.created * 1000).toLocaleDateString()}
                    </Field>
                    <Field>
                    <strong>Credits</strong>
                    {transaction.credits.toFixed(0)}
                    </Field>
                </TransactionItem>
                ))}
          </TransactionList>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default TransactionHistoryModal;
