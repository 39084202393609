import styled, { css, keyframes } from 'styled-components';

export const ChatContainer = styled.div`
    width: 80%;
    max-width: 900px;
    margin: 50px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 80vh;
`;





export const TopActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ccc;
`;




export const ChatHeader = styled.div`
    font-size: 26px;  // Increased size   
    font-weight: 600; // Heavier weight
    color: #007bff;
    text-align: center;
    flex-grow: 1; 
    padding: 0 20px; 
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
    letter-spacing: 1px; // Added letter-spacing for refinement
    text-transform: uppercase; // Convert text to uppercase
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); // Subtle shadow
    line-height: 1.2; // Increased line height for better spacing
`;


export const CancelButtonTop = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 10px; // Add a right margin
    &:hover {
        background-color: transparent;
    }
    opacity: 0.6;
`;







export const CancelIcon = styled.svg`
    width: 24px;
    height: 24px;
    stroke: #7a7a7a;  // Set the default color to gray
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    ${CancelButtonTop}:hover & {  // This targets the icon when its parent button is hovered
        stroke: blue;  // The color when the button is hovered, adjust as necessary
    }
`;

export const ChatBody = styled.div`
    padding: 20px;
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`;

export const ChatMessage = styled.div`
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: ${props => props.role === 'assistant' ? '#f0f0f0' : '#007bff'};
    color: ${props => props.role === 'assistant' ? '#000' : '#fff'};
    align-self: ${props => props.role === 'assistant' ? 'flex-start' : 'flex-end'};
    max-width: 70%;
    margin-left: ${props => props.role === 'assistant' ? '0' : '30%'};
    margin-right: ${props => props.role === 'assistant' ? '30%' : '0'};
`;

export const ChatFooter = styled.div`
    padding: 10px 20px;
    display: flex;
    align-items: flex-end;  // align to the bottom
    justify-content: space-between;
    border-top: 1px solid #ccc;
    // Removed position: relative;
`;


export const InputField = styled.textarea`
    flex: 1;
    padding: 10px 15px;
    padding-right: 45px;  /* space for send button */
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    resize: none;
    height: auto;
    min-height: 40px;
    max-height: 215px;
    overflow-y: auto;
    z-index: 1;
`;

const loadingDots = keyframes`
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);
    }
    40% {
        color: #007bff;
        text-shadow:
            .25em 0 0 #007bff,
            .5em 0 0 rgba(0,0,0,0);
    }
    60% {
        text-shadow:
            .25em 0 0 #007bff,
            .5em 0 0 #007bff;
    }
    100%, 80% {
        text-shadow:
            .25em 0 0 #007bff,
            .5em 0 0 #007bff;
    }
`;

export const InputWrapper = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    align-items: flex-end;
`;




export const SendButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: ${props => props.$isActive ? 'pointer' : 'not-allowed'};
    position: absolute;
    z-index: 2;
    padding: 0;
    width: 32px;
    height: 32px;
    right: 15px;
    bottom: 4px; 
    position: absolute;
    
    background-color: ${props => props.$isActive ? 'lightgreen' : 'transparent'};
    &:hover, &:focus, &:active {
       
        outline: none;
        background-color: transparent;
        box-shadow: none;
    }

    &:hover {
        background-color: ${props => props.$isActive ? 'lightgreen' : 'transparent'};
    }

    opacity: ${props => props.$isActive ? '1' : '0.5'}; // Use $isActive prop to control opacity

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.$isLoading && css`
        &:after {
            content: '...';
            animation: ${loadingDots} 1s steps(5, end) infinite;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    `}
`;

export const SendIcon = styled.svg`
    width: 70%;
    height: 70%;
    stroke: ${props => props.$isActive ? 'white' : '#d1d1d1'}; // Use $isActive prop to control stroke color
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    display: block;
    ${SendButton}:hover & { 
        stroke: ${props => props.$isActive ? 'white' : '#d1d1d1'};
    }
    
`;



export const ProcessIndicator = styled.span`
    font-size: 14px;
    color: #007bff;
    margin-left: 10px;
`;





