import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VerificationContainer, Title, Message, RedirectButton } from './EmailVerification.style';

function EmailVerification() {
    const location = useLocation();
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('Verifying...');
    const [isVerified, setIsVerified] = useState(false);
    const [isError, setIsError] = useState(false);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const verifyEmail = async (token) => {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/users/verify-email/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: token })
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Verification error data:', errorData);
                    throw new Error(errorData.detail || 'Email verification failed');
                }

                // Removed unused variable 'data'
                setVerificationStatus('Email verified successfully!');
                setIsVerified(true);

                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            } catch (error) {
                console.error('Verification error:', error);
                setVerificationStatus(error.toString());
                setIsError(true);
            }
        };

        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            verifyEmail(token);
        } else {
            setVerificationStatus('Invalid or missing token in the URL.');
            setIsError(true);
        }
    }, [location, navigate, BASE_URL]);

    return (
        <VerificationContainer>
            <Title>Email Verification</Title>
            <Message $isError={isError}>{verificationStatus}</Message>
            {isVerified && (
                <>
                    <p>You will be redirected to the login page shortly...</p>
                    <RedirectButton onClick={() => navigate('/login')}>Go to Login</RedirectButton>
                </>
            )}
            {isError && (
                <RedirectButton onClick={() => navigate('/')}>Return Home</RedirectButton>
            )}
        </VerificationContainer>
    );
}

export default EmailVerification;
