import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import DOMPurify from 'dompurify';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Set up the worker for pdfjs
GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/workers/pdf.worker.js';


const BASE_URL = process.env.REACT_APP_BASE_URL;



export const getErrorMessage = (error) => {
    let displayErrorMessage = "An error occurred"; // Default error message

    if (error instanceof Error) {
        try {
            // Assuming the error message is a stringified JSON
            let parsedMessage = JSON.parse(error.message);
            // Extracting the 'detail' field from the parsed JSON
            displayErrorMessage = parsedMessage.detail || error.message;
        } catch (e) {
            // If parsing fails, use the error's message directly
            displayErrorMessage = error.message;
        }
    }

    return displayErrorMessage;
}


  
  
// const handleResponse = async (response) => {
//     if (!response.ok) {
//         const errorMessage = await response.text().catch(() => response.statusText);
//         throw new Error(errorMessage);
//     }
    
//     // Log the raw response for debugging purposes
//     const rawResponse = await response.text();
//     console.log("Raw Response:", rawResponse);
    
//     // Parse the raw response back to JSON and return
//     return JSON.parse(rawResponse);
// };


const handleResponse = async (response) => {
    if (!response.ok) {
        const errorMessage = await response.text().catch(() => response.statusText);
        throw new Error(errorMessage);
    }
    
    // Log the raw response for debugging purposes
    const rawResponse = await response.text();
    console.log("Raw Response:", rawResponse);
    
    // Parse the raw response back to JSON and return
    return JSON.parse(rawResponse);
};










export const sanitizeAndLimitInput = (input) => {
    if (!input) return '';

    // Sanitize the input
    const sanitizedInput = DOMPurify.sanitize(input);

    // Limit the length to 10,000 characters
    return sanitizedInput.substring(0, 12000);
};

export const userSanitizeAndLimitInput = (input) => {
    if (!input) return '';

    // Sanitize the input
    const sanitizedInput = DOMPurify.sanitize(input);

    // Limit the length to 254 characters
    return sanitizedInput.substring(0, 254);
};

export const pdfToText = async (pdfData) => {
  const pdf = await getDocument({ data: pdfData }).promise;
  let text = '';
  
  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const content = await page.getTextContent();
    text += content.items.map(item => item.str).join(' ');
  }

  return text;
};



export const registerUser = async (userData) => {
    const { confirmPassword, ...restData } = userData;  // Destructuring to exclude confirmPassword

    const sanitizedData = { 
        ...restData, 
        username: userSanitizeAndLimitInput(restData.username),  
        email: userSanitizeAndLimitInput(restData.email), 
        password: userSanitizeAndLimitInput(restData.password) 
    };

    const response = await fetch(`${BASE_URL}/api/v1/users/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sanitizedData),
    });
    return handleResponse(response);
};




export const updateUser = async (userId, updatedData, csrfToken,accessToken) => {
    let payload = {
        currentPassword: updatedData.currentPassword || ''  // Set to empty string if not provided
    };

    if (updatedData.username && updatedData.username.trim() !== "") {
        payload.username = updatedData.username;
    }

    if (updatedData.email && updatedData.email.trim() !== "") {
        payload.email = userSanitizeAndLimitInput(updatedData.email);
    }
    
    if (updatedData.newPassword && updatedData.newPassword.trim() !== "") {
        payload.newPassword= userSanitizeAndLimitInput(updatedData.newPassword);
    }

    if (updatedData.confirmNewPassword && updatedData.confirmNewPassword.trim() !== "") {
        payload.confirmNewPassword = userSanitizeAndLimitInput(updatedData.confirmNewPassword);
    }

    

    // console.log("Payload:", payload);

   // Add access token and CSRF token to the request headers
    const response = await fetch(`${BASE_URL}/api/v1/users/${userId}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken, // CSRF token in custom header
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ user: payload }),
    });
    return handleResponse(response);
};


export const loginUser = async (credentials) => {
    const sanitizedEmail = userSanitizeAndLimitInput(credentials.email);
    const sanitizedPassword = userSanitizeAndLimitInput(credentials.password);

    const response = await fetch(`${BASE_URL}/api/v1/users/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            username: sanitizedEmail,
            password: sanitizedPassword
        }),
    });

    const user = await handleResponse(response);
    if (!user) {
        throw new Error('Invalid credentials');
    }
    // console.log("User after login:", user);
    return user;
};



  
// export const logoutUser = async (accessToken) => {
//     // console.log(`Logging out with accessToken: ${accessToken}, csrfToken: ${csrfToken}`);
//     const response = await fetch(`${BASE_URL}/api/v1/users/logout`, {
//       method: 'POST',
//       credentials: 'include',  // Ensures that cookies are sent with the request
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${accessToken}`,
//     },
//     });
//     return handleResponse(response);
// };

export const logoutUser = async (accessToken, csrfToken) => {
    const response = await fetch(`${BASE_URL}/api/v1/users/logout`, {
      method: 'POST',
      credentials: 'include',  // Ensures that cookies are sent with the request
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-CSRF-Token': csrfToken, // CSRF token in custom header
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
};


 

let debounceTimer;
let lastPromiseReject;

export const checkAuthStatus = () => {
  return new Promise((resolve, reject) => {
    // Clear the existing timer if it exists
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      // Reject the previous promise as it's superseded by a new call
      if (lastPromiseReject) {
        lastPromiseReject(new Error('Superseded by a new request'));
      }
    }

    // Save the reject function to reject the promise when a new call comes in
    lastPromiseReject = reject;

    // Set a new timer
    debounceTimer = setTimeout(async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/users/check-auth-status`, {
          method: 'GET',
          credentials: 'include', // Required to include HTTP-only cookies in the request
        });
        const data = await handleResponse(response);
        if (!data.access_token) {
          // Handle unauthenticated user, without setting an error state
          resolve(null);
        } else {
          resolve(data);
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        reject(error); // Reject the promise on error
        
      } finally {
        // Clear the timer and reset the reject function
        debounceTimer = null;
        lastPromiseReject = null;
      }
    }, 3000); // Debounce period of 3000 milliseconds
  });
};




export const createResume = async (resumeData,csrfToken,accessToken) => {
    // Sanitize the inputs
    resumeData.experienceSummary = sanitizeAndLimitInput(resumeData.experienceSummary);
    resumeData.targetJobDescription = sanitizeAndLimitInput(resumeData.targetJobDescription);

    // Make the API request
    const response = await fetch(`${BASE_URL}/api/v1/generate_resume/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            experience_summary: resumeData.experienceSummary,
            target_job_description: resumeData.targetJobDescription,
        }),
    });

    return handleResponse(response);
};



  
export const createCoverLetter = async (coverLetterData,csrfToken,accessToken) => {
    // Sanitize the inputs
    coverLetterData.resume = sanitizeAndLimitInput(coverLetterData.resume);
    coverLetterData.targetJobDescription = sanitizeAndLimitInput(coverLetterData.targetJobDescription);

    

    // Make the API request
    const response = await fetch(`${BASE_URL}/api/v1/generate_coverletter/`, { // Update the endpoint URL
        method: 'POST',
        credentials: 'include',
        headers: {
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            resume:  coverLetterData.resume,
            target_job_description: coverLetterData.targetJobDescription,
        }),
    });

    return handleResponse(response);
};






export const simulateInterview = async ({ resume, targetJobDescription, difficultyLevel, numberOfQuestions }) => {
    const payload = {
        resume: sanitizeAndLimitInput(resume),
        targetJobDescription: sanitizeAndLimitInput(targetJobDescription),
        difficultyLevel,
        numberOfQuestions
    };

    const response = await fetch(`${BASE_URL}/api/v1/interviews`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    return handleResponse(response);
};

export const generateReport = async (reportData) => {
    // Assuming there are some user input fields in reportData, you'll sanitize them too
    reportData.someField = sanitizeAndLimitInput(reportData.someField);
    // ... repeat for other fields
    const response = await fetch(`${BASE_URL}/api/v1/reports`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
    });
    return handleResponse(response);
};


export const requestPasswordReset = async (email) => {
    const sanitizedEmail = userSanitizeAndLimitInput(email);

    const response = await fetch(`${BASE_URL}/api/v1/users/reset-password-request/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: sanitizedEmail }),
    });

    return handleResponse(response);
};


export const resendVerificationEmail = async (email) => {
    const sanitizedEmail = userSanitizeAndLimitInput(email);

    const response = await fetch(`${BASE_URL}/api/v1/users/verify-email-request/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: sanitizedEmail }),
    });

    return handleResponse(response);
};



// Utility function to format message content for improved readability and professional presentation

export const formatMessage = (message) => {
  const styles = {
    container: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      lineHeight: '1.4',
      color: '#1a1a1a',
      margin: '0',
      padding: '0',
    },
    paragraph: {
      margin: '0 0 8px 0',
    },
    bulletPoint: {
      margin: '0 0 4px 0',
      paddingLeft: '16px',
    },
    bold: {
      fontWeight: '600',
    },
  };

  const formatContent = (message) => {
    const lines = message.split('\n').map(line => line.trim()).filter(Boolean);

    return (
      <div style={styles.container}>
        {lines.map((line, index) => formatLine(line, index))}
      </div>
    );
  };

  const formatLine = (line, key) => {
    if (line.startsWith('- ') || line.startsWith('* ')) {
      return <div key={key} style={styles.bulletPoint}>{formatInlineContent(line.slice(2))}</div>;
    } else {
      return <div key={key} style={styles.paragraph}>{formatInlineContent(line)}</div>;
    }
  };

  const formatInlineContent = (text) => {
    return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <span key={index} style={styles.bold}>{part.slice(2, -2)}</span>;
      }
      return part;
    });
  };

  return formatContent(message);
};

  



  
  
