import React, { useState } from 'react';
import usePasswordResetRequest from '../../hooks/usePasswordResetRequest';
import * as S from './ResetPasswordRequest.styles'; // Adjust the import path as necessary

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const passwordResetMutation = usePasswordResetRequest();

    const handleSubmit = async (event) => {
        event.preventDefault();
        passwordResetMutation.mutate(email, {
            onSuccess: (response) => {
                setMessage(response.detail);
            },
            onError: (error) => {
                setMessage(error.response?.data?.detail || 'An unexpected error occurred.');
            }
        });
    };

    return (
        <S.ResetPasswordContainer>
            <S.Title>Reset Password</S.Title>
            <S.Form onSubmit={handleSubmit}>
                <S.InputField 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Enter your email" 
                    required 
                />
                <S.SubmitButton type="submit" disabled={passwordResetMutation.isLoading}>
                    Send Reset Link
                </S.SubmitButton>
            </S.Form>
            {message && <S.Message>{message}</S.Message>}
            {passwordResetMutation.isLoading && (
                <S.LoadingOverlay>
                    <S.Spinner />
                    <p>Processing...</p>
                </S.LoadingOverlay>
            )}
        </S.ResetPasswordContainer>
    );
};

export default ResetPasswordRequest;
