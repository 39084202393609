



import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin: auto;
  max-width: 600px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;  // Ensures the title is centered
`;

export const Description = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;
`;

export const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #0056b3;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;  // Added margin for spacing
  transition: background-color 0.3s;

  &:hover {
    background-color: #004494;
  }

  &:disabled {
    background-color: #ccc;
    cursor: default;
  }
`;

export const StatusMessage = styled.p`
  font-size: 14px;
  color: #31708f;  // A color that stands out but is not too harsh
  background-color: #d9edf7;  // Light background for the status message
  border-color: #bce8f1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid transparent;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
