// src/hooks/useUpdateUser.js
import { useMutation } from 'react-query';
import { updateUser } from '../utils/api.js';
import { useUser } from '../context/UserContext';  // Import useUser

const useUpdateUser = (setSuccessMessage) => {
  const {dispatch} = useUser();  // Destructure setCurrentUser from the context

  return useMutation(
    ({ userId, updateData, csrfToken, accessToken}) => updateUser(userId, updateData, csrfToken, accessToken),
    {
      onSuccess: (updatedUser, variables) => {
        console.log('Update successful:', updatedUser)
        // Call setCurrentUser from context to update the currentUser
        // setCurrentUser(currentUser => ({ ...currentUser, ...updatedUser }));
        dispatch({ type: 'SET_CURRENT_USER', payload: updatedUser });
       
        setSuccessMessage("Profile updated successfully!");
        // dispatch({ type: 'SET_LOADING', payload: false });

        // Optionally update the query cache if you are using it elsewhere
        // queryClient.setQueryData(['user', variables.userId], updatedUser);
      },
      onError: (error) => {
        // Handle error
        console.error('Update error:', error); 
        // dispatch({ type: 'SET_LOADING', payload: false });
      },
    }
  );
};

export default useUpdateUser;
