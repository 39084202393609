import styled from 'styled-components';

// Base container
export const ResumeGeneratorContainer = styled.div`
  width: 100%;
  max-width: 1200px; 
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  font-family: 'Georgia', serif; 
  background-color: #f6f8fa;  
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50; 
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 10px;
`;

export const Error = styled.p`
  color: #e74c3c;  
  font-weight: bold;
  border: 1px solid #e74c3c;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #bdc3c7; 
  font-size: 16px;
  resize: vertical;
  border-radius: 5px; 
  font-family: 'Arial', sans-serif; 
`;

export const SubmitButton = styled.button`
  background: #3498db; 
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  transition: background-color 0.3s ease;
  font-size: 18px;

  &:hover {
    background-color: #2980b9; 
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

export const ResumePreview = styled.div`
  margin-top: 50px;
  border: 1px solid #bdc3c7;
  padding: 20px;
  background-color: #ffffff; 
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

export const ResumeHeader = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 1.8em;
  margin: 20px 0;
  color: #34495e; 
`;

export const ResumeSubHeader = styled.div`
  font-weight: bold;
  font-size: 1.5em; 
  margin: 15px 0;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 5px;
  color: #34495e; 
`;

export const ResumeItem = styled.div`
  font-size: 1.2em; 
  margin-left: 30px;
  color: #7f8c8d;  
  &::before {
    content: "";
    margin-right: 10px;
    color: #34495e; 
  }
`;

export const ResumeText = styled.div`
  font-size: 1.2em;
  margin-bottom: 15px; 
  color: #7f8c8d;  
`;

export const ResumeContact = styled.div`
  font-family: 'Arial', sans-serif;
  font-size: 1em;
  text-align: left;
  color: #34495e; 
`;

// Spinner and Loading Message Styles

export const LoadingBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; 
`;

export const SpinnerContainer = styled.div`
  text-align: center;
`;

export const EnhancedSpinner = styled.div`
  display: inline-block;

  & div {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background-color: #3498db;
    border-radius: 100%;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  & div:nth-child(1) {
    animation-delay: -0.32s;
  }

  & div:nth-child(2) {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

export const LoadingMessage = styled.p`
  margin-top: 20px;
  font-size: 1.5em;
  color: #ffffff;
`;





// In ResumeGenerator.styles.js
export const MockInterviewButton = styled.button`
  background-color: white; /* Start with a white background */
  color: #4caf50; /* Vibrant green for text for visibility */
  padding: 12px 30px; /* Larger padding for a bigger button */
  margin: 15px 0; /* Ensure it doesn't stick to other elements */
  border: 2px solid #4caf50; /* Green border to match text */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  font-size: 18px; /* Slightly larger font */
  font-weight: bold; /* Bold font for emphasis */
  transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: #4caf50; /* Vibrant green for background */
    color: white; /* White text for contrast */
    transform: scale(1.05); /* Slight increase in size */
    box-shadow: 0 2px 15px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Adjust font size for smaller devices */
    padding: 10px 20px; /* Adjust padding for smaller devices */
  }
`;


// In ResumeGenerator.styles.js
export const DownloadPDFButton = styled.button`
  background-color: #007bff; /* Primary color for the button */
  color: white;
  padding: 12px 30px;
  margin: 15px 0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: white; /* Reverse effect: background turns to white */
    color: #007bff; /* Text color changes to the original background color */
    border: 1px solid #007bff; /* Add border to maintain size */
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`;


// In ResumeGenerator.styles.js
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  align-items: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px; /* Additional space from the content above */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }
`;
