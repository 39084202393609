// src/context/UserContext.js

import React, { createContext, useContext, useReducer, useEffect } from 'react';
import * as api from '../utils/api';


const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

const actionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USER_AUTH_SUCCESS: 'SET_USER_AUTH_SUCCESS',
  UPDATE_STRIPE_CUSTOMER_ID: 'UPDATE_STRIPE_CUSTOMER_ID',
};

const initialState = {
  currentUser: null,
  error: null,
  isAuthenticated: false,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      if (action.payload === null) {
        return { ...state, currentUser: null };
      } else {
        return { ...state, currentUser: { ...state.currentUser, ...action.payload } };
      }
    case actionTypes.SET_USER_AUTH_SUCCESS:
      return { ...state, isAuthenticated: action.payload };
    case actionTypes.UPDATE_STRIPE_CUSTOMER_ID:
      return {
          ...state,
          currentUser: { ...state.currentUser, stripe_customer_id: action.payload },
        };
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isAuthenticated");
    
    const checkAuthStatus = async () => {
      try {
        const response = await api.checkAuthStatus();
        
        if (response.authenticated) {
          dispatch({ type: actionTypes.SET_CURRENT_USER, payload: response });
          dispatch({ type: actionTypes.SET_USER_AUTH_SUCCESS, payload: true });
        } else {
          dispatch({ type: actionTypes.SET_CURRENT_USER, payload: null });
          dispatch({ type: actionTypes.SET_USER_AUTH_SUCCESS, payload: false });
        }
      } catch (err) {
        localStorage.removeItem("isAuthenticated");
      
        console.error('Auth check failed:', err);
        
      } 
    };
    if (isLoggedIn) {
      checkAuthStatus();
    }
   
  }, []);


  

  const value = {
    currentUser: state.currentUser,
    isAuthenticated: state.isAuthenticated,
    dispatch,
  };

  
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
  
  
};


