import { useMutation } from 'react-query';
import { requestPasswordReset } from '../utils/api'; // Ensure this points to your API function

const usePasswordResetRequest = () => {
  return useMutation(
    (email) => requestPasswordReset(email),
    {
      onSuccess: (data) => {
        console.log('Password reset request successful:', data);
      },
      onError: (error) => {
        console.error('Password reset request error:', error);
      },
    }
  );
};

export default usePasswordResetRequest;
