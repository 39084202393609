import React from 'react';
import {
  FaqContainer,
  FaqHeading,
  Section,
  Subheading,
  Content,
  Highlight,
} from './Faq.styles'; // Ensure you create corresponding styled components in Faq.styles.js

const Faq = () => {
  return (
    <FaqContainer>
      <FaqHeading>Help Center</FaqHeading>

      <Section>
        <Subheading>Why Choose PrepForJob Over General AI Tools?</Subheading>
        <Content>
          In a world where versatile AI tools like ChatGPT and Claude can perform a wide range of tasks, it’s important to understand the advantages of using specialized platforms like PrepForJob. General AI tools are built to be adaptable across many use cases, but this versatility often means they lack the depth needed to excel in specific areas. Here’s why PrepForJob stands out:
          <ul>
            <li><strong>Optimized Workflow:</strong> Our platform is finely tuned to streamline the job preparation process, making it as efficient and effective as possible.</li>
            <li><strong>Domain-Specific Expertise:</strong> We leverage deep, targeted data training specific to job application processes, ensuring higher quality outputs.</li>
            <li><strong>Enhanced User Experience:</strong> The user interface is designed with job seekers in mind, offering a more intuitive and supportive experience.</li>
            <li><strong>Seamless Integration:</strong> We integrate with third-party services and tools relevant to job preparation, offering you a holistic solution.</li>
            <li><strong>Diverse Model Selection:</strong> We utilize a wide array of AI models from various providers, allowing us to choose the best tool for each specific task, rather than being limited to one company’s offerings.</li>
          </ul>
          <Highlight>PrepForJob is purpose-built to deliver superior results in the job preparation domain, providing tailored features and a user-centric experience that general AI tools simply can’t match.</Highlight>
        </Content>
      </Section>

      <Section>
        <Subheading>Maximizing Your Experience with Customization</Subheading>
        <Content>
          To fully benefit from PrepForJob, it’s crucial to harness the power of customization. The more context and specific information you provide, the more precisely our tools can cater to your needs. This doesn’t mean you have to input extensive details—sometimes a simple structure is enough for the AI to work effectively.
          <Highlight>Even if you provide minimal information, PrepForJob will generate a professional resume or cover letter. However, to ensure the content truly reflects your experience and skills, we recommend reviewing the output carefully and making sure it aligns with your personal history and professional goals.</Highlight>
        </Content>
      </Section>

      <Section>
        <Subheading>How to Use PrepForJob: A Step-by-Step Guide</Subheading>
        <Content>
          <ol>
            <li><strong>Sign In:</strong> Log in to your account on PrepForJob.</li>
            <li><strong>Top Up Credits:</strong> Add credits to your account. Our services are highly cost-effective—$1 for a comprehensive mock interview, $0.60 for a professional resume, and $0.50 for a tailored cover letter.</li>
            <li><strong>Access Your Dashboard:</strong> Upon logging in, you’ll be directed to your dashboard, where you can select the service you want to use.</li>
            <li><strong>Generate Your Resume:</strong> Choose the Resume Generator, input your Experience Summary and Target Job Description, and click "Generate Resume." You’ll receive a polished resume, ready for download as a PDF.</li>
            <li><strong>Create Your Cover Letter:</strong> Use the Cover Letter Creator to import your resume and target job description. The AI will help you craft a compelling cover letter that aligns with your resume.</li>
            <li><strong>Simulate an Interview:</strong> Navigate to the Interview Simulation, where you can upload or paste your resume and job description, select the difficulty level, and start the interview.</li>
            <li><strong>Review and Download:</strong> After the interview, you can download the entire session as a PDF. This document will be invaluable as you prepare for real interviews.</li>
            <li><strong>Receive Feedback:</strong> After a short wait, return to your dashboard to access your evaluation report and action plan. The evaluation report grades your performance, while the action plan offers actionable insights on how to improve.</li>
            <li><strong>Engage in a Q&A Session:</strong> Utilize the Q&A session under the evaluation report to ask follow-up questions and gain further insights from your interviewer.</li>
          </ol>
        </Content>
      </Section>

      <Section>
        <Subheading>Your Data Privacy and Security</Subheading>
        <Content>
          <Highlight>At PrepForJob, your privacy is our priority. We only retain your account information, evaluation reports, and action plans to help you track your progress. You have full control over your data and can delete your account and all associated data at any time through the "User Profile" section.</Highlight>
        </Content>
      </Section>
    </FaqContainer>
  );
};

export default Faq;
