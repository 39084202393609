import React , {useEffect} from 'react';
import {
  AboutContainer,
  Heading,
  Section,
  Subheading,
  Content,
  UnorderedList,
  ListItem,
  ContactInfo,
  ContactEmail,
  Address,
} from './AboutUs.styles'; // Ensure you have these styled components in AboutUs.styles.js

const AboutUs = () => {
  useEffect(() => {
    // localStorage.removeItem('retrieve');
    // This code executes after the component mounts
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []); // Empty dependency array means this runs once on mount

  return (
    <AboutContainer>
      <Heading>About Us</Heading>

      <Section>
        <Subheading>Our Mission</Subheading>
        <Content>
          At PrepForJob.io, we are dedicated to empowering job seekers with innovative tools and personalized support to achieve their career ambitions. Our mission is to transform the job application process with AI-driven solutions, enabling tailored resumes, cover letters, and comprehensive interview preparation.
        </Content>
      </Section>

      <Section>
        <Subheading>Who We Are</Subheading>
        <Content>
          PrepForJob.io was founded by a team of tech enthusiasts, HR experts, and career coaches. We are driven by a common goal: to simplify and enhance the job hunting process for everyone, regardless of their career level or background.
        </Content>
      </Section>

      <Section>
        <Subheading>What We Do</Subheading>
        <Content>
          PrepForJob.io provides three core services, each designed to give our users a distinct advantage in their job search:
          <UnorderedList>
            <ListItem>Custom Resume Generation: Utilizing advanced AI, we generate resumes optimized for both your experience and the specific job you're targeting.</ListItem>
            <ListItem>Tailored Cover Letter Creation: Our platform crafts personalized cover letters that effectively highlight your unique skills and experiences to potential employers.</ListItem>
            <ListItem>AI-Powered Simulated Interviews: We offer simulated interview experiences with feedback and actionable advice, preparing you for a wide range of interview scenarios.</ListItem>
          </UnorderedList>
        </Content>
      </Section>

      <Section>
        <Subheading>Our Values</Subheading>
        <Content>
          - Innovation: We are committed to continuous technological advancement to offer state-of-the-art career tools.
          <br />
          - Empathy: We understand the challenges of job searching and strive to provide solutions that are effective and supportive.
          <br />
          - Integrity: Upholding your trust is our top priority. We maintain the highest standards of data privacy and ethical practices.
        </Content>
      </Section>

      <Section>
        <Subheading>Our Community</Subheading>
        <Content>
          PrepForJob.io is more than just a service—it's a community. We foster a supportive environment where users can exchange experiences, advice, and encouragement.
        </Content>
      </Section>

      <Section>
        <Subheading>Join Us</Subheading>
        <Content>
          Whether you're starting your career, switching fields, or advancing in your current path, PrepForJob.io is here to guide you. Discover the future of job application preparation and take the next step in your career journey with us.
        </Content>
      </Section>

      <Section  id="contact-us">
        <Subheading>Contact Us</Subheading>
        <Content>
          For any inquiries or support issues, please reach out to us:
          <ContactInfo>
            Email: <ContactEmail href="mailto:support@prepforjob.io">support@prepforjob.io</ContactEmail>
            <br />
            Address: <Address>Marine Gateway, 450 SW Marine Dr Floor 18, Vancouver, BC V5X 0C3</Address>
          </ContactInfo>
        </Content>
      </Section>

    </AboutContainer>
  );
};

export default AboutUs;
