import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as S from './ReportPage.styles';
// import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PromotionModal from "../../components/PromotionModal/PromotionModal";

import { getErrorMessage } from '../../utils/api';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportPage = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
   
    const { currentUser } = useUser();
    const [reportData, setReportData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isApiLoading, setApiLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    // const [showErrorModal, setShowErrorModal] = useState(false);
    const [showPromotionModal, setShowPromotionModal] = useState(false);

    useEffect(() => {
        const fetchReport = async () => {
            if (!currentUser?.username) {
                return;
              }  
            try {
                const response = await fetch(`${BASE_URL}/api/v1/report/`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': currentUser.csrf_token,
                        'Authorization': `Bearer ${currentUser.access_token}`,
                        'username': currentUser?.username
                    }
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.detail || "Error fetching report.");
                }

                const data = await response.json();
                setReportData(data.report);
            } catch (errors) {
                console.error("Error fetching report:", errors);
                setErrors(errors.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchReport();
    }, [currentUser, BASE_URL]);

    const handleApiResponse = (response, responseData) => {
        if (response.ok && responseData && responseData.message) {
            navigate('/qa-chat', {
                state: {
                    initialMessage: responseData.message
                }
            });
        }
    };
    

    const handleQuestionAnswer = async () => {
        setApiLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/api/v1/question_answer/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': currentUser.csrf_token,
                    'Authorization': `Bearer ${currentUser.access_token}`,
                },
                
            });
    
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.detail || 'Unknown error');
            }
            handleApiResponse(response, responseData);
        } catch (error) {
            console.error('Error in API call:', error);
            const formattedError = getErrorMessage(error);
            console.log(formattedError);
            setError(formattedError);
            setShowPromotionModal(true);
            
        } finally {
            setApiLoading(false);
        }
    };
    

    const renderReportText = (text) => {
        const lines = text.split('\n');
        let currentSection = "";
        
        const sectionHandlers = {
            "interview evaluation report for": (line, index) => <S.ReportHeader key={`header-${index}`}>{line.trim()}</S.ReportHeader>,
            "1. summary": (line, index) => <S.ReportSubHeader key={`subheader-summary-${index}`}>{line.split(":")[0]}</S.ReportSubHeader>,
            "2. question-by-question analysis": (line, index) => <S.ReportSubHeader key={`subheader-qna-${index}`}>{line.split(":")[0]}</S.ReportSubHeader>,
            "3. strengths": (line, index) => <S.ReportSubHeader key={`subheader-strengths-${index}`}>{line.split(":")[0]}</S.ReportSubHeader>,
            "4. areas of improvement": (line, index) => <S.ReportSubHeader key={`subheader-improvement-${index}`}>{line.split(":")[0]}</S.ReportSubHeader>,
            "5. recommendations": (line, index) => <S.ReportSubHeader key={`subheader-recommendations-${index}`}>{line.split(":")[0]}</S.ReportSubHeader>,
            "6. additional notes": (line, index) => <S.ReportSubHeader key={`subheader-notes-${index}`}>{line.split(":")[0]}</S.ReportSubHeader>
        };
        
        return lines.map((line, index) => {
            if (line.toLowerCase().startsWith("interview evaluation report for")) {
                currentSection = "interview evaluation report for";
                return sectionHandlers[currentSection](line, index);
            }
            if (["1. summary", "2. question-by-question analysis", "3. strengths", "4. areas of improvement", "5. recommendations", "6. additional notes"].some(sec => line.toLowerCase().startsWith(sec))) {
                currentSection = line.toLowerCase();
                if (sectionHandlers[currentSection]) {
                    return sectionHandlers[currentSection](line, index);
                }
            } 
            // Avoid rendering the ReportDivider right after the header
            if (line.trim() === "" && lines[index - 1] && lines[index - 1].toLowerCase().startsWith("interview evaluation report for")) {
                return null;
            }
            if (line.trim() === "" && index > 0 && lines[index - 1].trim() !== "") {
                return <S.ReportDivider key={`divider-${index}`} />;
            } 
            else {
                return <S.ReportText key={`text-${index}`}>{line}</S.ReportText>;
            }
        });
      };
      
      
      const generateReportPDF = () => {
          const reportSections = reportData.split('\n');
          const content = [];
          
          reportSections.forEach((line, index) => {
              if (line.trim().toLowerCase().startsWith("interview evaluation report for")) {
                  // Top Header
                  content.push({
                      text: line.trim(),
                      fontSize: 26,
                      bold: true,
                      alignment: 'center',
                      margin: [0, 20, 0, 40],
                      color: '#0056b3'
                  });
              } else if (line.trim() === "") {
                  // Divider
                  content.push({
                      canvas: [{
                          type: 'line',
                          x1: 0, y1: 10,
                          x2: 515, y2: 10,
                          lineWidth: 1,
                          lineColor: '#e1e1e1'
                      }],
                      margin: [0, 20]
                  });
              } else if (["1. summary", "2. question-by-question analysis", "3. strengths", "4. areas of improvement", "5. recommendations", "6. additional notes"].some(sec => line.toLowerCase().startsWith(sec))) {
                  // Subheaders
                  content.push({
                      text: line.split(":")[0],
                      fontSize: 22,
                      color: '#007BFF',
                      margin: [0, 30, 0, 15]
                  });
              } else {
                  // Regular text
                  content.push({
                      text: line,
                      fontSize: 16,
                      margin: [0, 2]
                  });
              }
          });
          
          const documentDefinition = {
              content: content,
              defaultStyle: {
                  lineHeight: 1.5
              },
              pageSize: 'A4'
          };
          
          pdfMake.createPdf(documentDefinition).download("interview_report.pdf");
      };
      
          
     

    return (
        <S.ReportContainer>
            {isLoading ? (
                <S.LoadingOverlay>
                    <S.Spinner />
                    <S.LoadingText>Interview evaluation report is being generated, please wait…</S.LoadingText>
                </S.LoadingOverlay>
            ) : errors ? (
                <S.ErrorContainer>
                    <S.ErrorMessage>{errors}</S.ErrorMessage>
                </S.ErrorContainer>
            ) : (
                <>
                    {reportData && reportData.startsWith("Please participate in the interview") ? (
                        <>
                            <S.FriendlyReminder>{reportData}</S.FriendlyReminder>
                            <S.StartInterviewButton onClick={() => navigate('/interview')}>Start Interview Now</S.StartInterviewButton>
                        </>
                    ) : (
                        <>
                            <S.ReportContent>
                                {renderReportText(reportData)}
                            </S.ReportContent>
                            <S.QuestionAnswerButton onClick={handleQuestionAnswer}>
                                Interviewer Q&A
                            </S.QuestionAnswerButton>
                            <S.DownloadButton onClick={generateReportPDF}>
                                Download as PDF
                            </S.DownloadButton>
                        </>
                    )}
                </>
            )}
            {isApiLoading && (
                <S.SpinnerOverlay>
                    <S.Spinner />
                    <S.LoadingText>Preparing your Q&A session, please hold for a moment...</S.LoadingText>
                </S.SpinnerOverlay>
            )}
             {showPromotionModal && (
                <PromotionModal 
                header="Insufficient Credit Balance"
                promotionMessage={error} 
                onClose={() => setShowPromotionModal(false)}
                />
            )}
        </S.ReportContainer>
    );
};

export default ReportPage;
