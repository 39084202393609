// export const validateRegistration = (data) => {
//     let errors = {};
  
//     // Improved regex for email validation
//     const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
//     // Username validation
//     if (!data.username) {
//         errors.username = 'Username is required';
//     } else if (data.username.length < 1 || data.username.length > 255) {
//         errors.username = 'Username must be between 1 and 255 characters';
//     } else if (!/^[a-zA-Z0-9_-]+$/.test(data.username)) {
//         errors.username = 'Username can only contain alphanumeric characters, underscores, or hyphens.';
//     } else {
//         const forbiddenUsernames = ["admin", "support"];
//         if (forbiddenUsernames.includes(data.username.toLowerCase())) {
//             errors.username = `'${data.username}' is a reserved word. Please choose a different username.`;
//         }
//     }
  
//     // Email validation
//     if (!data.email) {
//         errors.email = 'Email is required';
//     } else if (!emailRegex.test(data.email)) {
//         errors.email = 'Email address is invalid';
//     }
  
//     // Password validation
//     if (!data.password) {
//         errors.password = 'Password is required';
//     } else if (data.password.length < 8) {
//         errors.password = 'Password must be at least 8 characters';
//     } else {
//         if (!/[A-Z]/.test(data.password)) {
//             errors.password = 'Password should contain at least one uppercase letter.';
//         } else if (!/[a-z]/.test(data.password)) {
//             errors.password = 'Password should contain at least one lowercase letter.';
//         } else if (!/[0-9]/.test(data.password)) {
//             errors.password = 'Password should contain at least one digit.';
//         }
//     }
  
//     // Confirm password validation
//     if (data.password !== data.confirmPassword) {
//         errors.confirmPassword = 'Passwords must match';
//     }
  
//     return errors;
//   };
  

export const validateLogin = (data) => {
  let errors = {};

  if (!data.email) {
      errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email address is invalid';
  }

  if (!data.password) {
      errors.password = 'Password is required';
  }

  return errors;
};



export const validateRegistration = (data) => {
    let errors = {};
  
    // Improved regex for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    // Username validation
    if (!data.username) {
        errors.username = 'Username is required';
    } else if (data.username.length < 1 || data.username.length > 255) {
        errors.username = 'Username must be between 1 and 255 characters';
    } else if (!/^[a-zA-Z0-9_-]+$/.test(data.username)) {
        errors.username = 'Username must contain only alphanumeric characters, underscores, or hyphens.';
    } else {
        const forbiddenUsernames = ["admin", "support", "root", "system"];
        if (forbiddenUsernames.includes(data.username.toLowerCase())) {
            errors.username = `'${data.username}' is a reserved word. Please choose a different username.`;
        }
    }
  
    // Email validation
    if (!data.email) {
        errors.email = 'Email is required';
    } else if (!emailRegex.test(data.email)) {
        errors.email = 'Email address is invalid';
    }
  
    // Password validation
    if (!data.password) {
        errors.password = 'Password is required';
    } else if (data.password.length < 8 || data.password.length > 64) {
        errors.password = 'Password must be between 8 and 64 characters';
    } else {
        if (!/[A-Z]/.test(data.password)) {
            errors.password = 'Password must contain at least one uppercase letter.';
        } else if (!/[a-z]/.test(data.password)) {
            errors.password = 'Password must contain at least one lowercase letter.';
        } else if (!/[0-9]/.test(data.password)) {
            errors.password = 'Password must contain at least one digit.';
        // eslint-disable-next-line no-useless-escape
        } else if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(data.password)) {
            errors.password = 'Password must contain at least one special character.';
        }
    }
  
    // Confirm password validation
    if (data.password !== data.confirmPassword) {
        errors.confirmPassword = 'Passwords must match';
    }
  
    return errors;
};

export const validateUpdate = (data) => {
    let errors = {};
  
    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    // Username validation
    if (data.username) {
        if (data.username.length < 1 || data.username.length > 255) {
            errors.username = 'Username must be between 1 and 255 characters';
        } else if (!/^[a-zA-Z0-9_-]+$/.test(data.username)) {
            errors.username = 'Username must contain only alphanumeric characters, underscores, or hyphens.';
        } else {
            const forbiddenUsernames = ["admin", "support", "root", "system"];
            if (forbiddenUsernames.includes(data.username.toLowerCase())) {
                errors.username = `'${data.username}' is a reserved word. Please choose a different username.`;
            }
        }
    }
  
    // New password validation
    if (data.newPassword) {
        // Current password validation
        if (!data.currentPassword) {
            errors.currentPassword = 'Current password is required';
        }
        if (data.newPassword.length < 8 || data.newPassword.length > 64) {
            errors.newPassword = 'Password must be between 8 and 64 characters';
        } else {
            if (!/[A-Z]/.test(data.newPassword)) {
                errors.newPassword = 'Password must contain at least one uppercase letter.';
            } else if (!/[a-z]/.test(data.newPassword)) {
                errors.newPassword = 'Password must contain at least one lowercase letter.';
            } else if (!/[0-9]/.test(data.newPassword)) {
                errors.newPassword = 'Password must contain at least one digit.';
            // eslint-disable-next-line no-useless-escape
            } else if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(data.newPassword)) {
                errors.newPassword = 'Password must contain at least one special character.';
            }
        }
    }
  
    // Confirm new password validation
    if (data.newPassword && data.confirmNewPassword) {
        if (data.newPassword !== data.confirmNewPassword) {
            errors.confirmNewPassword = 'Passwords must match';
        }
    }
  
    // Email validation
    if (data.email && !emailRegex.test(data.email)) {
        errors.email = 'Email address is invalid';
    }
  
    return errors;
};



// export const validateUpdate = (data) => {
//     let errors = {};
  
//     // Email validation regex
//     const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
   
    
  
//     // Username validation
//     if (data.username) {
//         if (data.username.length < 1 || data.username.length > 255) {
//             errors.username = 'Username must be between 1 and 255 characters';
//         } else if (!/^[a-zA-Z0-9_-]+$/.test(data.username)) {
//             errors.username = 'Username can only contain alphanumeric characters, underscores, or hyphens.';
//         } else {
//             const forbiddenUsernames = ["admin", "support"];
//             if (forbiddenUsernames.includes(data.username.toLowerCase())) {
//                 errors.username = `'${data.username}' is a reserved word. Please choose a different username.`;
//             }
//         }
//     }
  
//     // New password validation
//     if (data.newPassword) {
//          // Current password validation
//         if (!data.currentPassword) {
//             errors.currentPassword = 'Current password is required';
//         }
//         if (data.newPassword.length < 8) {
//             errors.newPassword = 'Password must be at least 8 characters';
//         } else {
//             if (!/[A-Z]/.test(data.newPassword)) {
//                 errors.newPassword = 'Password should contain at least one uppercase letter.';
//             } else if (!/[a-z]/.test(data.newPassword)) {
//                 errors.newPassword = 'Password should contain at least one lowercase letter.';
//             } else if (!/[0-9]/.test(data.newPassword)) {
//                 errors.newPassword = 'Password should contain at least one digit.';
//             }
//         }
//     }
  
//     // Confirm new password validation
//     if (data.newPassword && data.confirmNewPassword) {
//         if (data.newPassword !== data.confirmNewPassword) {
//             errors.confirmNewPassword = 'Passwords must match';
//         }
//     }
  
//     // Email validation
//     if (data.email && !emailRegex.test(data.email)) {
//         errors.email = 'Email address is invalid';
//     }
  
//     return errors;
// };




export const validateResume = (data) => {
    let errors = {};

    // Validate experienceSummary
    if (!data.experienceSummary || data.experienceSummary.trim() === "") {
        errors.experienceSummary = 'Experience summary is required';
    } else if (data.experienceSummary.length < 10) {
        errors.experienceSummary = 'Experience summary should be at least 10 characters';
    } else if (data.experienceSummary.length > 12000) {
        errors.experienceSummary = 'Experience summary should not exceed 12000 characters';
    }

    // Validate targetJobDescription
    if (!data.targetJobDescription || data.targetJobDescription.trim() === "") {
        errors.targetJobDescription = 'Target job description is required';
    } else if (data.targetJobDescription.length < 10) {
        errors.targetJobDescription = 'Target job description should be at least 10 characters';
    } else if (data.targetJobDescription.length > 12000) {
        errors.targetJobDescription = 'Target job description should not exceed 12000 characters';
    }

    return errors;
};




export const validateCoverLetter = (data) => {
    let errors = {};

    // Validate resume content
    if (!data.resume || data.resume.trim() === "") {
        errors.resume = 'Resume content is required';
    } else if (data.resume.length < 10) {
        errors.resume = 'Resume content should be at least 10 characters long';
    } else if (data.resume.length > 8000) {
        errors.resume = 'Resume content should be no more than 8000 characters long';
    }

    // Validate target job description
    if (!data.targetJobDescription || data.targetJobDescription.trim() === "") {
        errors.targetJobDescription = 'Target job description is required';
    } else if (data.targetJobDescription.length < 10) {
        errors.targetJobDescription = 'Target job description should be at least 10 characters long';
    } else if (data.targetJobDescription.length > 8000) {
        errors.targetJobDescription = 'Target job description should be no more than 8000 characters long';
    }

    return errors;
};





export const validateInterview = (data) => {
    let errors = {};
  
    // Validate difficultyLevel
    if (!data.difficultyLevel) {
      errors.difficultyLevel = 'Difficulty level is required';
    }
  
    // Validate resume
    if (!data.resume || data.resume.trim() === "") {
      errors.resume = 'Resume content is required';
    } else if (data.resume.length < 10) {
      errors.resume = 'Resume content is too short';
    } else if (data.resume.length > 12000) {
      errors.resume = 'Resume content exceeds maximum length';
    }
  
    // Validate targetJobDescription
    if (!data.targetJobDescription || data.targetJobDescription.trim() === "") {
      errors.targetJobDescription = 'Target job description is required';
    } else if (data.targetJobDescription.length < 10) {
      errors.targetJobDescription = 'Target job description is too short';
    } else if (data.targetJobDescription.length > 12000) {
      errors.targetJobDescription = 'Target job description exceeds maximum length';
    }
  
    return errors;
  };
  
