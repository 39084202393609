import React, { useState } from 'react';
import styled,{ css, createGlobalStyle } from 'styled-components';
import { useUser } from '../../context/UserContext';


const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #007bff;
    --hover-primary-color: #0056b3;
    --secondary-color: #6c757d;
    --hover-secondary-color: #5a6268;
    --background-color: #fff;
    --text-color: #333;
    --border-radius: 6px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContent = styled.div`
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: auto;
  max-width: 500px; // Responsive width
  font-family: var(--font-family);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px; // Spacing between form elements
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
  color: var(--text-color);
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  &:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;

  &:hover {
    background-color: var(--hover-primary-color);
    transform: translateY(-2px);
    box-shadow: var(--box-shadow);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
  }

  ${props => props.cancel && css`
    background-color: var(--secondary-color);
    &:hover {
      background-color: var(--hover-secondary-color);
    }
  `}
`;

const ErrorMessage = styled.div`
  color: #d9534f; // Changed to a more standard error color
  margin-top: 10px;
  font-weight: bold; // Make error message stand out
`;


const RefundModal = ({ isOpen, onClose, onRefund, BASE_URL }) => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const { currentUser} = useUser();
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const validateRefund = (credits) => {
    // Convert the input to a numeric value safely
    const creditsNumeric = Number(credits);

    if (isNaN( creditsNumeric) ||  creditsNumeric <= 0) {
      return 'Please enter a valid number of credits';
    }
    
    // Calculate the total amount in US cents from the given credits
    const totalCents = creditsNumeric * 0.01; // Since 1 credit = 0.01 US cents
  
    // // Check for minimum amount in US cents
    // if (totalCents < 50) {
    //   return "Minimum top-up amount is 50 US cents.";
    // }

    // Check if the total amount in US cents is a whole number
    if (!Number.isInteger(totalCents)) {
      return "Refund amount must be a whole number in US cents.";
    }
  
    // If both conditions are satisfied, return an empty string indicating no error
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Convert credits to USD amount if necessary
    const refundAmount = amount; // Implement conversion logic if needed

     // Call the validateTopUp function with the credits entered by the user
    const validationMessage = validateRefund(refundAmount);
    
    // Check if there was a validation error message returned
    if (validationMessage) {
        // If so, display the validation error message to the user and halt the submission
        // alert(validationMessage);
        setError(validationMessage)
        return;
        // throw new Error(validationMessage);
    }

    setIsLoading(true); // Start loading

    try {
      const response = await fetch(`${BASE_URL}/api/v1/refund/`, {
        method: 'POST',
        credentials: 'include', // Ensures that cookies are sent with the request
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': currentUser.csrf_token,
          'Authorization': `Bearer ${currentUser ? currentUser.access_token : ''}`,
        },
        body: JSON.stringify({ amount: refundAmount })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Refund request failed');
      }

      onRefund(); // Callback to update the parent component
      onClose(); // Close the modal after successful refund
      
    } catch (err) {
      console.log(err.message);
      // setError('Refund request failed');
      setError(err.message);
    } finally {
        setIsLoading(false); // Stop loading
      }
  };


//   Handler for clicks on the overlay
//   const handleOverlayClick = (event) => {
//     // Check if the click was on the overlay itself and not its children
//     if (event.target === event.currentTarget) {
//       onClose(); // Close the modal
//     }
//   };

  if (!isOpen) return null;

//   <ModalOverlay onClick={handleOverlayClick}>
//       <ModalContent onClick={e => e.stopPropagation()}></ModalContent>

  return (
    <>
    <GlobalStyle />
    <ModalOverlay>
      <ModalContent>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="creditAmount">Enter Credits for Refund:</Label>
          <Input
            id="creditAmount"
            type="number"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter your remaining credits"
          />
          <p>Refund Amount: {amount / 100} US cents</p>
          <ButtonContainer>
            <Button type="submit" disabled={isLoading} loading={isLoading}>
              {isLoading ? 'Processing...' : 'Request Refund'}
            </Button>
            <Button onClick={onClose} disabled={isLoading} cancel>
              Close
            </Button>
          </ButtonContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </ModalContent>
    </ModalOverlay>
    </>
  );
};

export default RefundModal;
