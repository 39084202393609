import React, { createContext, useState, useContext } from 'react';
import { createCoverLetter,getErrorMessage } from '../utils/api.js';
import { useUser } from './UserContext';

const CoverLetterContext = createContext();

export const useCoverLetter = () => {
    return useContext(CoverLetterContext);
};

export const CoverLetterProvider = ({ children }) => {
    const { currentUser} = useUser();
    const [coverLetter, setCoverLetter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const [showErrorModal, setShowErrorModal] = useState(false);
    const [showPromotionModal, setShowPromotionModal] = useState(false);

    const generateCoverLetter = async (coverLetterData) => {
        setLoading(true);
        try {
            const generatedCoverLetter = await createCoverLetter(coverLetterData,currentUser.csrf_token,currentUser.access_token);
            setCoverLetter(generatedCoverLetter);
            setLoading(false);
            return generatedCoverLetter;
        } catch (err) {
            setLoading(false);
            // setError(err.message);
            const formattedError = getErrorMessage(err);
            // console.log(formattedError);
            setError(formattedError);
            setShowPromotionModal(true);
            return null;
        }
    };

    const clearCoverLetter = () => {
        setCoverLetter(null);
    };

    const clearError = () => {
        setError(null);
    };

    const contextValue = {
        coverLetter,
        loading,
        error,
        showPromotionModal,
        generateCoverLetter,
        clearCoverLetter,
        clearError,
        setCoverLetter,
        setShowPromotionModal
    };

    return (
        <CoverLetterContext.Provider value={contextValue}>
            {children}
        </CoverLetterContext.Provider>
    );
};

// export default CoverLetterContext;
