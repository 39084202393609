// src/hooks/useLoginUser.js
import { useMutation } from 'react-query';
import { loginUser } from '../utils/api'; 
import { useUser } from '../context/UserContext';

const useLoginUser = () => {
  const {dispatch } = useUser();

  return useMutation(
    (credentials) => loginUser(credentials),
    {
      onSuccess: (user) => {
       
        localStorage.setItem("isAuthenticated", "true");
        
        dispatch({ type: 'SET_CURRENT_USER', payload: user });
        dispatch({ type: 'SET_USER_AUTH_SUCCESS', payload: true });
       
      },
      onError: (error) => {
        console.error('Login error:', error);
        
      },
    }
  );
};

export default useLoginUser;
