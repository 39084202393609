import styled from 'styled-components';
import { colors, fonts, spacing } from '../Common.styles';

export const PolicyContainer = styled.div`
  background-color: ${colors.background}; 
  padding: ${spacing.sectionPadding};
  color: ${colors.primary};
  font-family: ${fonts.body};
  margin: 0 auto;
  line-height: 1.6;
  font-size: 1rem;
  max-width: 800px;
`;

export const PolicyHeading = styled.h1`
  font-family: ${fonts.heading};
  font-size: 2rem;
  color: ${colors.accent};
  text-align: center;
  margin-bottom: ${spacing.headingMargin};
`;

export const LastUpdated = styled.p`
  font-size: 0.875rem;
  color: ${colors.secondary};
  text-align: center;
  margin-bottom: 2rem;
`;

export const Section = styled.section`
  margin-bottom: ${spacing.sectionPadding};
`;

export const Subheading = styled.h2`
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  color: ${colors.secondary};
  margin-bottom: ${spacing.headingMargin};
`;

export const Content = styled.p`
  font-size: 1rem;
  color: ${colors.primary};
  margin-bottom: ${spacing.contentMargin};
  white-space: pre-line;
`;

export const List = styled.ul`
  list-style: inside square;
  padding-left: 0;
  margin-bottom: ${spacing.contentMargin};
`;

export const ListItem = styled.li`
  font-size: 0.875rem;
  color: ${colors.primary};
  margin-bottom: 10px;
`;

export const ContactEmail = styled.a`
  font-size: 0.875rem;
  color: ${colors.accent};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const ExternalLink = styled.a`
  font-size: 0.875rem;
  color: ${colors.accent};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
