import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Reset some default browser styles */
  body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
    margin: 0;
    padding: 0;
  }

  /* Set a background color and text styling for the entire application */
  body {
    background-color: #f3f4f6; /* Light gray background */
    font-family: 'Arial', sans-serif; /* Default font family */
    color: #333; /* Default text color */
    line-height: 1.6;
  }

  /* Style for headings */
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: #444;
  }

  /* Style for links */
  a {
    text-decoration: none;
    color: #0077cc; /* Default link color */
    transition: color 0.3s;

    &:hover {
      color: #0055a8;
    }
  }

  /* Generic button styling */
  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #0077cc;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0055a8;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  /* Generic form input styling */
  input, textarea, select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
