import styled from 'styled-components';

export const VerificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
`;

export const Title = styled.h1`
    color: #333;
    margin-bottom: 20px;
`;

export const Message = styled.p`
    color: ${props => props.$isError ? '#ff3860' : '#23d160'};
    font-size: 1.2em;
    margin: 10px 0;
    text-align: center;
`;

export const RedirectButton = styled.button`
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #43a047;
    }
`;
