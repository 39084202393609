// Spinner.jsx
import React from 'react';
import { SpinnerOverlay, SpinnerContainer, SpinnerText } from './Spinner.styles';

const Spinner = ({ text = 'Loading...' }) => {
  return (
    <SpinnerOverlay>
      <SpinnerContainer />
      <SpinnerText>{text}</SpinnerText> {/* Displaying loading text */}
    </SpinnerOverlay>
  );
};

export default Spinner;
