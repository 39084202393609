// src/components/Layout/Layout.jsx

import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  
`;

const Content = styled.main`
  flex: 1; // Takes up all available space between Navbar and Footer
  overflow: auto; // Allows the main content to scroll
  
`;


const ContentWrapper = styled.div`
  background: #f7f9fc; // Background color matching your content
  padding-bottom: 20vh; // Padding to push content above the footer
`;

const Layout = ({ children }) => {
  return (
    <PageLayout>
      <Navbar />
      <ContentWrapper>
        <Content>{children}</Content>
      </ContentWrapper>
      <Footer />
    </PageLayout>
  );
};

export default Layout;



