import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ResetPassword.styles'; // Adjust the import path as necessary

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        // Extract the token from the URL query parameters
        const query = new URLSearchParams(location.search);
        const tokenParam = query.get('token');
        if (tokenParam) {
            setToken(tokenParam);
        } else {
            setMessage('Invalid or missing token in the URL.');
            setIsError(true);
        }
    }, [location]);

    const handleResetPassword = async (event) => {
        event.preventDefault();
        setIsError(false);
        setIsSuccess(false);
        setIsLoading(true); // Set loading to true

        if (newPassword !== confirmPassword) {
            setMessage('New password and confirmation do not match.');
            setIsError(true);
            setIsLoading(false); // Reset loading state
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/api/v1/users/reset-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, new_password: newPassword, confirm_password: confirmPassword })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Password reset failed');
            }

            setMessage('Password reset successfully!');
            setIsSuccess(true);
            setTimeout(() => navigate('/login'), 5000);
        } catch (error) {
            console.error('Reset password error:', error);
            setMessage(error.toString());
            setIsError(true);
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Eye icons
    const EyeOpen = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

    const EyeClosed = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 2.5L21.5 21.5" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

    return (
        <S.ResetPasswordContainer>
            <S.Title>Reset Password</S.Title>
            <S.Form onSubmit={handleResetPassword}>
            <S.InputWrapper>
                <S.Input
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    required
                />
                <S.ToggleButton onClick={togglePasswordVisibility}>
                    {showPassword ? <EyeClosed /> : <EyeOpen />}
                </S.ToggleButton>
            </S.InputWrapper>
            <S.InputWrapper>
                <S.Input
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm New Password"
                    required
                />
                <S.ToggleButton onClick={togglePasswordVisibility}>
                    {showPassword ? <EyeClosed /> : <EyeOpen />}
                </S.ToggleButton>
            </S.InputWrapper>
                <S.SubmitButton type="submit" disabled={isLoading}>
                    {isLoading ? 'Resetting...' : 'Reset Password'}
                </S.SubmitButton>
            </S.Form>
            <S.Message isError={isError}>{message}</S.Message>
            {isSuccess && <S.Message>You will be redirected to the login page shortly...</S.Message>}
        </S.ResetPasswordContainer>
    );
};

export default ResetPassword;
