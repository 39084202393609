// src/index.js

import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';







// Drop console logs in production
if (process.env.NODE_ENV === 'production') {
    console.log = console.warn = console.error = () => {};
  }


// Unregister any active service worker
serviceWorkerRegistration.unregister();

const root = createRoot(document.getElementById('root'));
root.render(
      <App />
  );




