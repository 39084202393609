import React, { useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import { useUser } from '../../context/UserContext';
import Spinner from '../../components/Spinner/Spinner';
import { loadStripe } from '@stripe/stripe-js';
import TopUpModal from '../../components/TopUpModal/TopUpModal';
import TransactionHistoryModal from '../TransactionHistory/TransactionHistoryModal';
import RefundModal from '../RefundModal/RefundModal';

const DashboardContainer = styled.div`
  background-color: #f3f4f6; /* Light gray background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
`;

const BalanceDisplay = styled.div`
  font-size: 1.5rem; /* 24px */
  color: #333; /* Dark text for contrast */
  margin: 15px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;


const ErrorMessage = styled.div`
  color: #d32f2f; /* Red color for errors */
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffebee; /* Light red background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  font-size: 1rem; /* 16px */
`;

const Button = styled.button`
  background-color: #007bff; /* Primary blue */
  color: white;
  border: none;
  padding: 12px 20px; /* Slightly larger padding for a modern look */
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* 16px */
  font-weight: 500; /* Medium font weight for better readability */
  transition: all 0.3s ease; /* Smooth transition for hover and focus effects */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

  &:hover {
    background-color: #0056b3; /* Slightly darker blue on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover for a lifting effect */
  }

  &:focus {
    outline: none;
    border: 2px solid #0056b3; /* Highlight focus with a border */
    background-color: #0056b3; /* Consistent with hover state */
  }

  &:disabled {
    background-color: #cccccc; /* Grayed out when disabled */
    color: #666666;
    cursor: not-allowed;
    box-shadow: none; /* No shadow when disabled */
  }
`;




const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const Creditbalance = () => {
  const [creditBalance, setCreditBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useUser();
  const isLoggedIn = localStorage.getItem("isAuthenticated");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
//   const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [fetchCounter, setFetchCounter] = useState(0);

  


const fetchCreditBalance = useCallback(async () => {
    console.log("access token in fetchCreditBalance:", currentUser.access_token);
    setIsLoading(true);
    setShowSpinner(true); // Ensure spinner is shown right before the delay starts
    
    // Delay the fetch call by 3000 milliseconds (3 seconds)
    setTimeout(async () => {
      try {
        console.log("how many times I have been called")
        const response = await fetch(`${BASE_URL}/api/v1/credit_balance/`, {
          method: 'GET',
          credentials: 'include', // Ensures that cookies are sent with the request
          headers: {
            'Authorization': `Bearer ${currentUser ? currentUser.access_token : ''}`,
            'X-CSRF-Token': currentUser.csrf_token,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.detail || 'Error fetching credit balance');
        setCreditBalance(data.credit_balance);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        setShowSpinner(false);
      }
    }, 3000); // 3000 milliseconds delay
  }, [BASE_URL, currentUser]);
  

// useEffect(() => {
//     localStorage.removeItem('retrieve');
//   }, []); // Runs once on component mount  
  
useEffect(() => {
    if (isLoggedIn && currentUser) {
      fetchCreditBalance();
    } else {
      setShowSpinner(false);
    }
  }, [isLoggedIn, currentUser, fetchCounter,fetchCreditBalance]);
  

  const handleTopUp = () => setIsTopUpModalOpen(true);
  const handleModalClose = () => setIsTopUpModalOpen(false);
  const handleOpenTransactionHistory = () => setIsTransactionModalOpen(true);
  const handleCloseTransactionHistory = () => setIsTransactionModalOpen(false);
  const handleOpenRefundModal = () => setIsRefundModalOpen(true);
  const handleCloseRefundModal = () => setIsRefundModalOpen(false);



const incrementFetchCounter = () => {
    setFetchCounter((prevCounter) => prevCounter + 1);
  };
  


const onPaymentSuccess = () => {
    handleModalClose();
    incrementFetchCounter(); // This will trigger the fetch
  };

const handleRefund = () => {
    incrementFetchCounter(); // This will trigger the fetch
  };


  if ( isLoggedIn && !currentUser) {
    return <Spinner text="Preparing your account balance page..."/>;
  }

  return (
    <DashboardContainer>
      <h2>Credit Balance</h2>
      {showSpinner ? <Spinner text="Preparing your account balance page..." /> : null}
      {isLoading ? <p>Loading...</p> : null}
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <BalanceDisplay>{creditBalance}</BalanceDisplay>
      <Button onClick={handleTopUp}>Top Up</Button>
      <Button onClick={handleOpenRefundModal}>Refund</Button>
      <Button onClick={handleOpenTransactionHistory}>View Transactions</Button>
      <TopUpModal
        isOpen={isTopUpModalOpen}
        onClose={handleModalClose}
        stripePromise={stripePromise}
        onPaymentSuccess={onPaymentSuccess}
        BASE_URL={BASE_URL}
      />
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={handleCloseRefundModal}
        onRefund={handleRefund}
        BASE_URL={BASE_URL}
      />
      <TransactionHistoryModal
        isOpen={isTransactionModalOpen}
        onClose={handleCloseTransactionHistory}
        BASE_URL={BASE_URL}
        stripeCustomerId={currentUser.stripeCustomerId}
      />
    </DashboardContainer>
  );
};

export default Creditbalance;
