// import styled from 'styled-components';

// export const HomeContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   padding: 50px;
//   background-color: #f7f9fc;
//   height: 100vh;
// `;



// export const Header = styled.h1`
//   font-size: 2.5rem; // Slightly smaller than Navbar brand
//   color: #333;
//   margin-bottom: 20px;
//   text-transform: uppercase;
//   font-weight: normal; // Less emphasis compared to Navbar brand
// `;


// export const Description = styled.p`
//   width: 60%;
//   font-size: 1.4rem;
//   color: #555;
//   margin-bottom: 40px;
//   line-height: 1.6;
//   text-align: center;
// `;

// export const ActionButtons = styled.div`
//   margin-bottom: 50px;
//   display: flex;
//   justify-content: center;
// `;

// export const LoginButton = styled.button`
//   padding: 10px 30px;
//   margin-right: 20px;
//   background-color: transparent;
//   color: #333;
//   border: 2px solid #333;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s, color 0.3s;

//   &:hover {
//     background-color: #333;
//     color: #fff;
//   }
// `;

// export const RegisterButton = styled(LoginButton)`
//   background-color: #007bff;
//   color: white;
//   border: none;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// export const Features = styled.div`
//   width: 80%;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   margin-top: 50px;
// `;

// export const Feature = styled.div`
//   flex-basis: calc(33.33% - 20px);
//   background: #fff;
//   padding: 20px;
//   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
//   margin: 10px;
// `;

// export const FeatureTitle = styled.h3`
//   font-size: 1.8rem;
//   color: #007bff;
//   margin-bottom: 20px;
//   text-transform: uppercase;
// `;

// export const FeatureDescription = styled.p`
//   font-size: 1.2rem;
//   color: #555;
//   line-height: 1.5;
// `;




// import styled from 'styled-components';

// // Defining breakpoints for responsiveness
// const breakpoints = {
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
// };

// // Container for the entire page
// export const HomeContainer = styled.div`
//   padding: 20px;
//   margin: 0 auto;
//   max-width: 1200px;

//   @media (max-width: ${breakpoints.lg}) {
//     padding: 15px;
//   }

//   @media (max-width: ${breakpoints.md}) {
//     padding: 10px;
//   }
// `;

// // Styling for the header
// export const Header = styled.h1`
//   font-size: 2.5rem;
//   text-align: center;
//   margin-bottom: 30px;

//   @media (max-width: ${breakpoints.md}) {
//     font-size: 2rem;
//   }

//   @media (max-width: ${breakpoints.sm}) {
//     font-size: 1.5rem;
//   }
// `;

// // Description text styling
// export const Description = styled.p`
//   font-size: 1.2rem;
//   text-align: center;
//   margin-bottom: 20px;

//   @media (max-width: ${breakpoints.md}) {
//     font-size: 1rem;
//   }
// `;

// // Container for buttons
// export const ActionButtons = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 15px;
//   margin-bottom: 30px;
// `;

// // Button styling
// const Button = styled.button`
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 1rem;
//   transition: background-color 0.3s ease;

//   &:hover {
//     opacity: 0.9;
//   }
// `;

// // Specific styling for Login and Register buttons
// export const LoginButton = styled(Button)`
//   background-color: #007bff;
//   color: white;
// `;

// export const RegisterButton = styled(Button)`
//   background-color: #28a745;
//   color: white;
// `;

// // Features section styling
// export const Features = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 20px;
//   margin-bottom: 20px;

//   @media (max-width: ${breakpoints.lg}) {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   @media (max-width: ${breakpoints.md}) {
//     grid-template-columns: 1fr;
//   }
// `;

// // Individual feature styling
// export const Feature = styled.div`
//   background-color: #f8f9fa;
//   padding: 15px;
//   border-radius: 10px;
// `;

// export const FeatureTitle = styled.h3`
//   font-size: 1.5rem;
//   margin-bottom: 15px;
// `;

// export const FeatureDescription = styled.p`
//   font-size: 1rem;
// `;

// // Feel free to customize colors, font sizes, spacing, and add additional styling as needed.



import styled from 'styled-components';

// Color palette for the design
const colors = {
  darkBlue: '#2c3e50',
  lightGray: '#ecf0f1',
  green: '#27ae60',
  blue: '#2980b9',
  darkGray: '#95a5a6',
};

// Responsive breakpoints
const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const HomeContainer = styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  color: ${colors.darkGray};

  @media (max-width: ${breakpoints.lg}) {
    padding: 15px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 10px;
  }
`;

export const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  color: ${colors.darkBlue};
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.md}) {
    font-size: 2.5rem;
  }

  @media (max-width: ${breakpoints.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  font-size: 1.2rem;
  text-align: center;
  color: ${colors.darkGray};
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 20px; 
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-weight: bold;

  &:hover {
    opacity: 0.9;
  }
`;

export const LoginButton = styled(Button)`
  background-color: ${colors.blue};
  color: white;
`;

export const RegisterButton = styled(Button)`
  background-color: ${colors.green};
  color: white;
`;

export const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

export const Feature = styled.div`
  background-color: ${colors.lightGray};
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

export const FeatureTitle = styled.h3`
  font-size: 1.8rem;
  color: ${colors.darkBlue};
  margin-bottom: 15px;
`;

export const FeatureDescription = styled.p`
  font-size: 1.1rem;
  color: ${colors.darkGray};
`;

// Additional styling can be added here
