import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import useLogoutUser from '../../hooks/useLogoutUser';
import * as S from './HomePage.styles';
import Spinner from '../../components/Spinner/Spinner';


const HomePage = () => {
  const { currentUser,isAuthenticated} = useUser();
  console.log("isAuthenticated in HomePage", isAuthenticated)
  console.log("currentUser in HomePage", currentUser)
  const { mutateAsync: logout } = useLogoutUser();
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isAuthenticated");


  const handleAuthenticationAction = async () => {
    if (currentUser) {
      console.log("currentUser in HomePage", currentUser);
      try {
        console.log("currentUser in HomePage before logout", currentUser);
        await logout({
          csrfToken: currentUser.csrf_token,
          accessToken: currentUser.access_token});
        console.log("currentUser's access token in HomePage after logout", currentUser.access_token);
        navigate('/');
        
      } catch (error) {
        console.error('Logout failed:', error);
       
      }
    }
  };
  
  if ( isLoggedIn && !currentUser) {
    return <Spinner text="Preparing your homepage..."/>;
  }

  return (
    <S.HomeContainer>
      <S.Header>
      Empowering Your Career Journey
      </S.Header>
      <S.Description>
      Elevate your professional portfolio with our suite of tools. Seamlessly create dynamic resumes, craft personalized cover letters, and engage in AI-powered interview simulations to enhance your job market readiness!
      </S.Description>
      <S.ActionButtons>
        {isAuthenticated ? (
          <S.LoginButton onClick={handleAuthenticationAction}>Logout</S.LoginButton>
        ) : (
          <Link to="/login">
            <S.LoginButton>Login</S.LoginButton>
          </Link>
        )}
        {!isAuthenticated && (
          <Link to="/register">
            <S.RegisterButton>Register</S.RegisterButton>
          </Link>
        )}
      </S.ActionButtons>
      <S.Features>
        <S.Feature>
          <S.FeatureTitle>Dynamic Resume Generator</S.FeatureTitle>
          <S.FeatureDescription>Automate your resume creation with our advanced tool, tailored to your needs.</S.FeatureDescription>
        </S.Feature>
        <S.Feature>
          <S.FeatureTitle>AI-Powered Interview Simulation</S.FeatureTitle>
          <S.FeatureDescription>Experience real-time simulated interviews and get instant feedback.</S.FeatureDescription>
        </S.Feature>
        <S.Feature>
          <S.FeatureTitle>Personalized Cover Letter Creator</S.FeatureTitle>
          <S.FeatureDescription>Stand out from the crowd with a cover letter that speaks your worth.</S.FeatureDescription>
        </S.Feature>
      </S.Features>
      <S.Feature>
          <S.FeatureTitle>Interview Evaluation Report</S.FeatureTitle>
          <S.FeatureDescription>Get detailed feedback post-interview with actionable insights, available on your dashboard after every interview simulation.</S.FeatureDescription>
        </S.Feature>
        <S.Feature>
          <S.FeatureTitle>Interactive Q&A Session</S.FeatureTitle>
          <S.FeatureDescription>Engage directly with interviewers in Q&A sessions to gain deeper insights and prepare effectively for future opportunities.</S.FeatureDescription>
        </S.Feature>
        <S.Feature>
          <S.FeatureTitle>PDF Download for All Features</S.FeatureTitle>
          <S.FeatureDescription>Easily download well-formatted PDFs of your resumes, cover letters, and reports for offline use and sharing.</S.FeatureDescription>
        </S.Feature>
        <S.Feature>
          <S.FeatureTitle>Peace of Mind with Our Services</S.FeatureTitle>
          <S.FeatureDescription>Discover the ease and assurance of using our services. Add credits as per your needs and rest easy knowing you can refund any unused credits effortlessly. We prioritize your convenience and satisfaction.</S.FeatureDescription>
        </S.Feature>
      
      {!isAuthenticated && (
        <S.ActionButtons>
          <Link to="/register">
            <S.RegisterButton>Register Now - Get a Free Resume!</S.RegisterButton>
          </Link>
        </S.ActionButtons>
      )}
    </S.HomeContainer>
  );
};

export default HomePage;