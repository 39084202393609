import styled from 'styled-components';

export const MockInterviewContainer = styled.div`
    position: relative;  // Added for overlay positioning
    background-color: #f7f7f7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 650px;
    margin: 0 auto;
`;

export const Title = styled.h1`
    font-size: 28px;
    text-align: center;
    color: #2C3E50;
    margin-bottom: 30px;
`;

export const Introduction = styled.p`
    margin-bottom: 25px;
    font-size: 18px;
    text-align: justify;
    color: #34495E;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const InputField = styled.input`
    padding: 12px 18px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #3498DB;
        outline: none;
    }
`;

export const SelectField = styled.select`
    padding: 12px 18px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #3498DB;
        outline: none;
    }
`;

export const TextArea = styled.textarea`
    padding: 12px 18px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
    height: 120px;
    resize: vertical;

    &:focus {
        border-color: #3498DB;
        outline: none;
    }
`;

export const FileInputContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const FileInput = styled.input`
    display: none; // hide the default input
`;

export const FileInputLabel = styled.label`
    padding: 12px 18px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ECF0F1;
    transition: background 0.3s, border-color 0.3s;

    &:hover {
        background: #E0E7E9;
    }
`;

export const FileName = styled.span`
    font-size: 14px;
    color: #7F8C8D;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70%;
    margin-left: 10px;
`;

export const SubmitButton = styled.button`
    padding: 12px 18px;
    background-color: #3498DB;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #2980B9;
    }

    &:disabled {
        background-color: #BDC3C7;
        cursor: not-allowed;
    }
`;

export const Error = styled.span`
    color: #E74C3C;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
`;

// New styles for the loading spinner and overlay
export const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
`;

export const Spinner = styled.div`
    border: 5px solid rgba(245, 245, 245, 0.3);
    border-radius: 50%;
    border-top: 5px solid #3498DB;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

export const LoadingText = styled.p`
    font-size: 18px;
    color: #34495E;
    text-align: center;
`;

