import styled from 'styled-components';
import { colors, fonts, spacing } from '../Common.styles';

export const TermsContainer = styled.div`
  background-color: ${colors.background};
  padding: ${spacing.sectionPadding};
  color: ${colors.primary};
  font-family: ${fonts.body};
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const Heading = styled.h1`
  font-family: ${fonts.heading};
  font-size: 2rem;
  color: ${colors.accent};
  text-align: center;
  margin-bottom: ${spacing.headingMargin};
`;

export const LastUpdated = styled.p`
  font-size: 0.875rem;
  color: ${colors.secondary};
  text-align: center;
  margin-bottom: 2rem;
`;

export const Section = styled.section`
  margin-bottom: ${spacing.sectionPadding};
`;

export const Subheading = styled.h2`
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  color: ${colors.secondary};
  margin-bottom: ${spacing.headingMargin};
`;

export const Content = styled.p`
  font-size: 1rem;
  margin-bottom: ${spacing.contentMargin};
  line-height: 1.6;
  color: ${colors.primary};
`;
