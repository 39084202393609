import React, { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { validateLogin } from '../../utils/formValidation';
import { userSanitizeAndLimitInput } from '../../utils/api';
import * as S from './Login.styles';
import { getErrorMessage } from '../../utils/api';
import ErrorModal from "../../components/ErrorModal/ErrorModal"
import useLoginUser from '../../hooks/useLoginUser';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();
  const {isLoading, error, mutateAsync: login } = useLoginUser();
  const [showPassword, setShowPassword] = useState(false);  
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = userSanitizeAndLimitInput(value);
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  
  
  const errorMessage = getErrorMessage(error);
  console.log("errorMessage", errorMessage);
  const Loading = isLoading;
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateLogin(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await login(formData);
      navigate('/dashboard');
    } catch (err) {
      setShowErrorModal(true);
    }
  };

  const EyeOpen = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const EyeClosed = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5 2.5L21.5 21.5" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  

  // Google OAuth configuration
  const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const redirectUri = encodeURIComponent(`${BASE_URL}/api/v1/users/login-google/`);
  const clientId = '444717796168-0sd9q56hgp0mef7uf8b3am0d5gbh6ve3.apps.googleusercontent.com';
  const scope = encodeURIComponent('openid email profile');
  const responseType = 'code';
  const accessType = 'offline';
  const authLink = `${googleAuthUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=${accessType}`;

  const handleGoogleLogin = () => {
    window.location.href = authLink;
  };


  return (
    <S.LoginContainer>
      <S.Form onSubmit={handleSubmit}>
        <S.Title>Login to PrepForJob</S.Title>
        {showErrorModal && (
          <ErrorModal 
            errorMessage={errorMessage}
            onClose={() => setShowErrorModal(false)} 
          />
        )}
        <S.Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          error={errors.email}
          autoComplete="email" 
        />
        {errors.email && <S.Error>{errors.email}</S.Error>}
        <S.InputWrapper>
          <S.Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
            autoComplete="current-password"
          />
          <S.ToggleButton onClick={togglePasswordVisibility}>
            {showPassword ? <EyeClosed /> : <EyeOpen />}
          </S.ToggleButton>
        </S.InputWrapper>
        {errors.password && <S.Error>{errors.password}</S.Error>}
        <S.SubmitButton type="submit" disabled={Loading}>
          {Loading ? 'Logging in...' : 'Login'}
        </S.SubmitButton>
        <S.ForgotPasswordLink>
          <Link to="/reset-password-request">Forgot Password?</Link>
        </S.ForgotPasswordLink>
        <S.GoogleLoginButton  type="button" onClick={(e) => {
            e.stopPropagation();
            handleGoogleLogin();
        }}>
          Login with Google
      </S.GoogleLoginButton>
      </S.Form>
      
    </S.LoginContainer>
  );
};

export default Login;






