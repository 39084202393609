import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useUser } from '../../context/UserContext';
import { validateRegistration } from '../../utils/formValidation';
import { getErrorMessage } from '../../utils/api'; 
import * as S from './Registration.styles';
import { userSanitizeAndLimitInput } from '../../utils/api';
import ErrorModal from '../ErrorModal/ErrorModal';
import useRegisterUser from '../../hooks/useRegisterUser';

const Registration = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  // const { register, loading, error } = useUser();
  const { mutate: register, isLoading, error } = useRegisterUser();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [generalError, setGeneralError] = useState('');


  


  useEffect(() => {
    if (error) {
      console.log("Error detected in useEffect:", error);
      const message = getErrorMessage(error);
      setGeneralError(message);
      setShowErrorModal(true);
    }
    return () => setGeneralError('');  // Clear error when component unmounts or error changes
  }, [error]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = userSanitizeAndLimitInput(value);
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const errorMessage = getErrorMessage(error);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateRegistration(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    
    register(formData, {
      onError: (error) => {
        console.error("Error during registration:", error);
        // Error handling is done by the useEffect hook
      },
      onSuccess: () => {
        // Navigate to the email confirmation page after successful registration
        navigate('/email-confirmation');
      },
    });
  };

  const EyeOpen = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const EyeClosed = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12C1 12 4 4 12 4C20 4 23 12 23 12C23 12 20 20 12 20C4 20 1 12 1 12Z" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5 2.5L21.5 21.5" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  return (
    <S.RegistrationContainer>
      <S.Form onSubmit={handleSubmit}>
        <S.Title>Register to PrepForJob</S.Title>
        {showErrorModal && (
        <ErrorModal 
          errorMessage={generalError}
          onClose={() => setShowErrorModal(false)} 
        />
      )}
        <S.Input
          type="text"
          name="username"
          placeholder="Username"
          value={formData.username}
          onChange={handleChange}
          error={errors.username}
        />
        {errors.username && <S.Error>{errors.username}</S.Error>}
        <S.Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          error={errors.email}
        />
        {errors.email && <S.Error>{errors.email}</S.Error>}
        <S.InputWrapper>
          <S.Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
          />
          <S.ToggleButton onClick={togglePasswordVisibility}>
            {showPassword ? <EyeClosed /> : <EyeOpen />}
          </S.ToggleButton>
        </S.InputWrapper>
        {errors.password && <S.Error>{errors.password}</S.Error>}
        <S.InputWrapper>
          <S.Input
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            error={errors.confirmPassword}
          />
          <S.ToggleButton onClick={togglePasswordVisibility}>
            {showPassword ? <EyeClosed /> : <EyeOpen />}
          </S.ToggleButton>
        </S.InputWrapper>
        {errors.confirmPassword && <S.Error>{errors.confirmPassword}</S.Error>}
        <S.SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? 'Registering...' : 'Register'}
        </S.SubmitButton>
      </S.Form>
    </S.RegistrationContainer>
  );
};

export default Registration;
