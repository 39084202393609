// .spinner-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(255, 255, 255, 0.8);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 9999; /* Ensures the spinner is above all other content */
//   }
  
//   .spinner-container {
//     border: 5px solid rgba(0, 0, 0, 0.1);
//     border-top: 5px solid blue; /* Use your primary color here */
//     border-radius: 50%;
//     width: 50px;
//     height: 50px;
//     animation: spin 1s linear infinite;
//   }
  
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
  


// Spinner.styles.js
// import styled, { keyframes } from 'styled-components';

// // Animation for the spinner
// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `;

// // Overlay that covers the entire screen
// export const SpinnerOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(255, 255, 255, 0.8);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 9999;
// `;

// // Spinner element
// export const SpinnerContainer = styled.div`
//   border: 5px solid rgba(0, 0, 0, 0.1);
//   border-top: 5px solid blue; // Use your brand's primary color
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   animation: ${rotate} 1s linear infinite;
// `;



// Spinner.styles.js
import styled, { keyframes } from 'styled-components';

// Animation for the spinner
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Overlay that covers the entire screen
export const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

// Spinner element
export const SpinnerContainer = styled.div`
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid blue; // Use your brand's primary color
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  animation: ${rotate} 1s linear infinite;
`;

// Text displayed under the spinner
export const SpinnerText = styled.p`
  font-size: 18px;
  color: #333; // Adjust text color as needed
  text-align: center;
  margin-top: 20px;
`;
