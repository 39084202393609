import styled from 'styled-components';

export const ResetPasswordContainer = styled.div`
    background-color: #F8F9FA;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 50px auto;
    text-align: center;
`;

export const Title = styled.h1`
    font-size: 30px;
    color: #34495E;
    margin-bottom: 20px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
`;

export const InputField = styled.input`
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #DDE1E6;
    font-size: 16px;

    &:focus {
        border-color: #3498DB;
        box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
        outline: none;
    }
`;

export const SubmitButton = styled.button`
    padding: 15px;
    background-color: #3498DB;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #2980B9;
    }

    &:disabled {
        background-color: #BDC3C7;
        cursor: not-allowed;
    }
`;

export const Message = styled.p`
    margin-top: 15px;
    font-size: 16px;
    color: #2C3E50;
`;

export const Error = styled.span`
    color: #C0392B;
    font-size: 14px;
`;

// Optional styles for loading animation and overlay (if needed)
export const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

export const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498DB;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
