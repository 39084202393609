import styled from 'styled-components';

export const ResetPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    padding: 40px;
    background: linear-gradient(135deg, #e9efff 0%, #f4f4f4 100%);
`;

export const Title = styled.h1`
    color: #333;
    font-family: 'Helvetica Neue', sans-serif;
    margin-bottom: 30px;
    font-size: 2em;
    font-weight: 600;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 350px;
    border: 1px solid #e1e1e1;
    padding: 30px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const InputWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
`;

export const Input = styled.input`
    padding: 15px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    &:focus {
        border-color: #a4cbeb;
    }
`;

export const ToggleButton = styled.span`
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 24px;
    height: 24px;
`;

export const SubmitButton = styled.button`
    padding: 15px;
    background-image: linear-gradient(to right, #6eafff, #8bc6ec);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Helvetica Neue', sans-serif;
    transition: background-image 0.3s;
    &:hover {
        background-image: linear-gradient(to left, #8bc6ec, #6eafff);
    }
`;


// A higher-order component to filter out the `isError` prop
const withFilteredProps = WrappedComponent => {
  return ({ isError, ...otherProps }) => {
    return <WrappedComponent {...otherProps} />;
  };
};

export const Message = styled(withFilteredProps(({ className, children }) => <p className={className}>{children}</p>))`
    color: ${props => props.isError ? '#ff3860' : '#23d160'};
    font-size: 1em;
    margin: 10px 0;
    text-align: center;
    font-family: 'Arial', sans-serif;
`;



// export const ErrorMessage = styled.p`
//     color: #ff3860;
//     /* rest of your styles */
// `;

// export const SuccessMessage = styled.p`
//     color: #23d160;
//     /* rest of your styles */
// `;

// import { ErrorMessage, SuccessMessage } from './YourStyledComponentsFile';

// {isError ? 
//     <ErrorMessage>{message}</ErrorMessage> : 
//     <SuccessMessage>{message}</SuccessMessage>
// }