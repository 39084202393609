import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { resendVerificationEmail } from '../../utils/api'; // Ensure this is the correct import path
import {useUser} from '../../context/UserContext'; // Update this import path as necessary
import * as S from './EmailConfirmationPage.styles';

const EmailConfirmationPage = () => {
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const [isResending, setIsResending] = useState(false);
  const [resendStatus, setResendStatus] = useState(null);

  const handleLoginNavigation = () => {
    navigate('/login');
  };

  const handleResendEmail = async () => {
    if (!currentUser || !currentUser.email) {
      setResendStatus('No email address found for the current user.');
      return;
    }

    setIsResending(true);
    try {
      await resendVerificationEmail(currentUser.email);
      setResendStatus('Verification email resent successfully. Please check your inbox.');
    } catch (error) {
      console.error('Error resending email:', error);
      setResendStatus('Failed to resend email. Please try again later.');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <S.ConfirmationContainer>
      <S.Title>Email Confirmation</S.Title>
      <S.Description>
        Thank you for registering with SimuJob! Please check your email and confirm your registration to activate your account.
      </S.Description>
      <S.SubmitButton onClick={handleLoginNavigation}>
        Proceed to Sign In
      </S.SubmitButton>
      <S.SubmitButton onClick={handleResendEmail} disabled={isResending}>
        {isResending ? 'Resending...' : 'Resend Verification Email'}
      </S.SubmitButton>
      {resendStatus && <S.StatusMessage>{resendStatus}</S.StatusMessage>}
    </S.ConfirmationContainer>
  );
};

export default EmailConfirmationPage;
