import React, { useState, useEffect } from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useCoverLetter } from '../../context/CoverLetterContext';
import { sanitizeAndLimitInput, pdfToText} from '../../utils/api';
import { validateCoverLetter } from '../../utils/formValidation';
// import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PromotionModal from "../../components/PromotionModal/PromotionModal";
import * as S from './CoverLetterCreator.styles';
import Spinner from '../../components/Spinner/Spinner';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CoverLetterCreator = () => {
    const { generateCoverLetter, coverLetter, setCoverLetter,loading, error,showPromotionModal,setShowPromotionModal } = useCoverLetter();
    const [formData, setFormData] = useState({
        resume: '',
        targetJobDescription: ''
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const { currentUser } = useUser();
    const navigate = useNavigate();

    const encouragingMessages = [
        "Relax, we're crafting your cover letter...",
        "Just a moment, making things perfect for you...",
        "Grab a cup of coffee, we're almost there...",
        "Thanks for waiting, it'll be worth it!"
    ];

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % encouragingMessages.length);
            }, 5000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleFileChange = async (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const parsedText = await pdfToText(event.target.result);
                setFormData(prevData => ({
                    ...prevData,
                    [fieldName]: sanitizeAndLimitInput(parsedText)
                }));
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: sanitizeAndLimitInput(value)
        }));
    };

    const handleMockInterviewClick = () => {
        navigate("/interview"); // Assuming '/mock-interview' is the route
      };


    const renderCoverLetterText = (coverLetterData) => {
        if (!coverLetterData) return null;
    
        // Breaking the cover letter text into paragraphs
        const paragraphs = coverLetterData.split('\n').filter(paragraph => paragraph.trim() !== '');
    
        return (
          <div>
            {paragraphs.map((paragraph, index) => {
                // If the paragraph starts with "Warm regards", "Sincerely", etc., treat it as a sign-off
                const signOffs = ["Warm regards", "Sincerely", "Best regards", "Kind regards"];
                if (signOffs.some(signOff => paragraph.startsWith(signOff))) {
                    return (
                        <S.CoverLetterSegment key={index}>
                            <S.CoverLetterSignOff>
                                <S.CoverLetterParagraph>{paragraph}</S.CoverLetterParagraph>
                            </S.CoverLetterSignOff>
                        </S.CoverLetterSegment>
                    );
                }
    
                return (
                    <S.CoverLetterSegment key={index}>
                        <S.CoverLetterParagraph>{paragraph}</S.CoverLetterParagraph>
                        {(index < paragraphs.length - 1) && <S.CoverLetterHorizontalLine />}
                    </S.CoverLetterSegment>
                );
            })}
          </div>
        );
    };
    
    
    
    
    

    const generateCoverLetterPDF = (coverLetterData) => {
        // Breaking the cover letter text into paragraphs
        const paragraphs = coverLetterData.split('\n').filter(paragraph => paragraph.trim() !== '');
    
        const content = [];
    
        paragraphs.forEach((paragraph, index) => {
            const signOffs = ["Warm regards", "Sincerely", "Best regards", "Kind regards"];
            
            if (signOffs.some(signOff => paragraph.startsWith(signOff))) {
                content.push({
                    text: paragraph,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 20, 0, 5],
                    alignment: 'left'
                });
            } else {
                content.push({
                    text: paragraph,
                    fontSize: 14,
                    margin: [0, 10, 0, 10],
                    alignment: 'left'
                });
            }
    
            if (index < paragraphs.length - 1) {
                content.push({
                    canvas: [{
                        type: 'line',
                        x1: 0, y1: 5,
                        x2: 515, y2: 5,
                        lineWidth: 0.5,
                        lineColor: '#e1e1e1'
                    }],
                    margin: [0, 20]
                });
            }
        });
    
        const documentDefinition = {
            content: [
                { text: 'Cover Letter', fontSize: 24, bold: true, alignment: 'center', margin: [0, 0, 0, 30], color: '#007BFF' },
                ...content
            ],
            defaultStyle: {
                lineHeight: 1.5,
                fontFamily: 'Georgia'
            },
            pageSize: 'A4'
        };
    
        pdfMake.createPdf(documentDefinition).download('CoverLetter.pdf');
    };
    
    
    
    
    
    
             
    

   

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateCoverLetter(formData);
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors)
          return;
        }
        try {
            const response = await generateCoverLetter(formData);
            setCoverLetter(response);
        } catch (err) {
          console.error(err);
        }
      };

    



    if (!currentUser) {
        return <Spinner text = "Preparing your cover letter creator..."/>;
      }

    return (
        <S.CoverLetterContainer>
            <S.Title>Create Your Personalized Cover Letter</S.Title>
            {showPromotionModal && (
                <PromotionModal 
                header="Insufficient Credit Balance"
                promotionMessage={error} 
                onClose={() => setShowPromotionModal(false)}
                />
            )}
            {/* {error && <S.ErrorMessage>{error}</S.ErrorMessage>} */}
            <S.Form onSubmit={handleSubmit}>
                <label>Upload your resume (or paste below):</label>
                <S.FileInput type="file" accept=".pdf" onChange={(e) => handleFileChange(e, 'resume')} />
                <S.TextArea
                    name="resume"
                    placeholder="Paste your resume here"
                    value={formData.resume}
                    onChange={handleChange}
                />
                {validationErrors.resume && <S.Error>{validationErrors.resume}</S.Error>}

                <label>Target Job Description (or upload above):</label>
                <S.FileInput type="file" accept=".pdf" onChange={(e) => handleFileChange(e, 'targetJobDescription')} />
                <S.TextArea
                    name="targetJobDescription"
                    placeholder="Describe your target job Description"
                    value={formData.targetJobDescription}
                    onChange={handleChange}
                />
                {validationErrors.targetJobDescription && <S.Error>{validationErrors.targetJobDescription}</S.Error>}

                <S.SubmitButton type="submit">Create Cover Letter</S.SubmitButton>
            </S.Form>
            {loading && (
                <S.LoadingBackdrop>
                    <S.SpinnerContainer>
                        <S.EnhancedSpinner>
                            <div></div>
                            <div></div>
                            <div></div>
                        </S.EnhancedSpinner>
                        <S.LoadingMessage>{encouragingMessages[currentMessageIndex]}</S.LoadingMessage>
                    </S.SpinnerContainer>
                </S.LoadingBackdrop>
            )}
            {coverLetter && (
                <S.Preview>
                    {renderCoverLetterText(coverLetter.coverletter)}
                    {/* <button onClick={() => generateCoverLetterPDF(coverLetter.coverletter)}>Download as PDF</button> */}
                    <S.ButtonContainer>
                        <S.DownloadPDFButton onClick={() => generateCoverLetterPDF(coverLetter.coverletter)}>
                        Download as PDF
                        </S.DownloadPDFButton>
                        <S.MockInterviewButton onClick={() => handleMockInterviewClick()}>
                        Ace Interviews
                        </S.MockInterviewButton>
                    </S.ButtonContainer>
                </S.Preview>
            )}
        </S.CoverLetterContainer>
    );
};

export default CoverLetterCreator;











































