import React from 'react';
import {
  PricingContainer,
  PricingHeading,
  Section,
  Subheading,
  Content,
  PriceDetails,
  CreditInfo,
  CostBreakdown,
} from './Pricing.styles'; // Ensure you have these styled components in Pricing.styles.js

const Pricing = () => {
  return (
    <PricingContainer>
      <PricingHeading>Pricing</PricingHeading>

      <Section>
        <Subheading>Understanding Our Credit System</Subheading>
        <Content>
          At PrepForJob.io, we use a credit-based system to provide our services. Here's how it works:
          <CreditInfo>1 credit = 0.01 US cents</CreditInfo>
        </Content>
      </Section>

      <Section>
        <Subheading>Service Costs</Subheading>
        <Content>
          Our services are priced based on the credits required for each. Below is the breakdown:
          <PriceDetails>
            <CostBreakdown>
              <strong>Resume Generation:</strong> <span>6000 credits ($0.60 USD)</span>
            </CostBreakdown>
            <CostBreakdown>
              <strong>Cover Letter Creation:</strong> <span>5000 credits ($0.50 USD)</span>
            </CostBreakdown>
            <CostBreakdown>
              <strong>Mock Interview:</strong> <span>10000 credits ($1.00 USD)</span>
            </CostBreakdown>
            <CostBreakdown>
              <strong>Interviewer Q&A:</strong> <span>10000 credits ($1.00 USD)</span>
            </CostBreakdown>
          </PriceDetails>
        </Content>
      </Section>

      <Section>
        <Subheading>Why Choose Us?</Subheading>
        <Content>
          At PrepForJob.io, we believe in offering transparent pricing with no hidden costs. Your satisfaction is our priority, and our credit-based system ensures you only pay for what you use.
        </Content>
      </Section>
    </PricingContainer>
  );
};

export default Pricing;
