import React from 'react';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
    position: absolute;
    top: -10px;   // Adjust this for desired distance from the form's top edge
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
    transition: opacity 0.3s ease;
`;


const ModalContent = styled.div`
    background: #ffffff;
    padding: 15px 20px; // Adjusted padding for a smaller modal
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px; // Adjusted max-width for a smaller modal
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(10%);
    opacity: 0.9;
    &:hover {
        transform: translateY(0);
        opacity: 1;
    }
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.2s ease;
    &:hover {
        transform: scale(1.1);
    }
`;

const ErrorHeader = styled.h2`
    font-size: 20px; // Adjusted for a smaller modal
    margin-bottom: 15px;
    color: #e74c3c;
    border-bottom: 2px solid #e74c3c;
    padding-bottom: 7px;
`;

const ErrorMessage = styled.p`
    font-size: 16px; // Adjusted for a smaller modal
    color: #555;
    line-height: 1.5;
`;



const ErrorModal = ({ header = "An error occurred", errorMessage, onClose }) => {
    return (
        <ModalBackdrop onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <ErrorHeader>{header}</ErrorHeader>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </ModalContent>
        </ModalBackdrop>
    );
};

export default ErrorModal;

