import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from '../ChatPage/ChatPage.styles';
import { useUser } from '../../context/UserContext';
import { sanitizeAndLimitInput,formatMessage } from '../../utils/api'; 
import Spinner from '../../components/Spinner/Spinner';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const QAChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);
    const { currentUser } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const initialMessage = location.state?.initialMessage || "Something went wrong with your Q&A request. Please return to the previous page and try again.";
    // const blockCancelRef = useRef(false);
    const [conversationEnded, setConversationEnded] = useState(false);


    useEffect(() => {
        // const retrieve = localStorage.getItem('retrieve') === 'true';
        // Function to fetch conversation history
        const fetchConversationHistory = async () => {
            if (currentUser) {
                console.log("currentUser in chatpage useEffect:", currentUser);
                try {
                    const response = await fetch(`${BASE_URL}/api/v1/Qachat/`, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': currentUser.csrf_token,
                            'Authorization': `Bearer ${currentUser.access_token}`,
                        },
                        body: JSON.stringify({ 
                            message: "reload", 
                            retrieve_conversation: true, 
                            user: { username: currentUser.username }
                        })
                    });
                    const data = await response.json();
                    if (response.ok && data.qasession) {
                        const startIndex = data.qasession.findIndex(message => 
                            message.role === 'assistant' && message.content === initialMessage);
    
                        // Update state with messages from the initial message onwards
                        if (startIndex !== -1) {
                            setMessages(data.qasession.slice(startIndex));
                        } else {
                            // If initial message not found, set all messages
                            setMessages(data.qasession);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching conversation history:", error);
                    // Handle error appropriately
                }
            }
        };

        fetchConversationHistory();
        
        return () => {
            if ( currentUser) {
                console.log("currentUser at Cancel", currentUser)
                fetch(`${BASE_URL}/api/v1/Qachat/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': currentUser.csrf_token,
                        'Authorization': `Bearer ${currentUser.access_token}`,
                    },
                    body: JSON.stringify({ 
                        message: "Cancel", 
                        user: { username: currentUser?.username }
                    })
                });
            }
        };
    }, [currentUser, initialMessage, navigate, BASE_URL]);

    const adjustTextAreaHeight = () => {
        if (inputRef.current) {
            if (inputRef.current.value === '') {
                inputRef.current.style.height = '40px';
            } else {
                inputRef.current.style.height = 'inherit';
                inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
            }
        }
    };

    const handleUserInput = (e) => {
        setUserInput(sanitizeAndLimitInput(e.target.value));
        adjustTextAreaHeight();
    };

    const handleSubmit = async () => {
        if (userInput.trim() === '' || conversationEnded) return;
        setIsLoading(true);
        setMessages(prevMessages => [...prevMessages, { role: 'user', content: userInput }]);
        setUserInput('');

        if (inputRef.current) {
            inputRef.current.style.height = '40px';
        }
        try {

                const response = await fetch(`${BASE_URL}/api/v1/Qachat/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': currentUser.csrf_token,
                        'Authorization': `Bearer ${currentUser.access_token}`,
                    },
                    body: JSON.stringify({ 
                        message: userInput, 
                        user: { username: currentUser.username }
                    })
                });
                const data = await response.json();
                setIsLoading(false);
                if (response.ok && data && data.message) {
                    console.log("data.message", data.message);
                    // Check if the assistant's response includes the termination message
                    if (data.message.includes("Thank you for engaging in this Q&A session")) {
                        setConversationEnded(true);
                        // blockCancelRef.current = true; // Set the flag to block the cancel action
                        // localStorage.setItem('retrieve', 'true');
                        setMessages(prev => [...prev, { role: 'assistant', content: data.message }]);
                        // Set a timeout for 60 seconds before navigating back to the dashboard
                        // setTimeout(() => {
                        //     navigate('/dashboard');
                        // }, 60000); // 60000 milliseconds = 60 seconds
                    }  else {
                    setMessages(prev => [...prev, { role: 'assistant', content: data.message }]);
                        }
                    }
            } catch (error) {
                console.error("Error in handleSubmit:", error);
                setIsLoading(false);
                // Handle error appropriately, maybe set an error message in state
            }
        };
    

    const handleCancel = () => {
        navigate('/dashboard');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const generatePDF = () => {
        const docDefinition = {
            content: [
                // Add a title for your chat history PDF with more styling
                {
                    text: 'Q&A Conversation History',
                    style: 'header',
                    alignment: 'center',
                    margin: [0, 0, 0, 30] // Add space above and below the title
                },
                // Map through each message and apply more distinct styling
                ...messages.map((msg, index) => {
                    let speakerStyle, messageStyle;
                    if (msg.role === 'user') {
                        speakerStyle = 'userSpeaker';
                        messageStyle = 'userMessageContent';
                    } else {
                        speakerStyle = 'assistantSpeaker';
                        messageStyle = 'assistantMessageContent';
                    }
                    return {
                        columns: [
                            {
                                // Display the speaker name with styling
                                text: `${msg.role === 'user' ? 'You' : 'Assistant'}: `,
                                style: speakerStyle
                            },
                            {
                                // Display the message content with styling
                                text: msg.content,
                                style: messageStyle
                            }
                        ],
                        // Add space between messages
                        margin: [0, 5, 0, 15],
                    };
                })
            ],
            styles: {
                header: {
                    fontSize: 22,
                    bold: true
                },
                userSpeaker: {
                    bold: true,
                    color: '#0052cc', // A shade of blue typically associated with links
                    margin: [0, 0, 10, 0]
                },
                assistantSpeaker: {
                    bold: true,
                    color: '#4C4C4C', // Dark grey for contrast
                    margin: [0, 0, 10, 0]
                },
                userMessageContent: {
                    color: '#333333', // Slightly lighter grey than the assistant
                    background: '#f2f4f8', // Very light grey background
                    borderRadius: 5,
                    padding: [5, 10, 5, 10] // Padding inside the message bubble
                },
                assistantMessageContent: {
                    color: '#202020', // Nearly black for good contrast
                    background: '#e1ecea', // Light blue/green background
                    borderRadius: 5,
                    padding: [5, 10, 5, 10]
                }
            },
            defaultStyle: {
                columnGap: 3,
                fontSize: 12
            }
        };
    
        pdfMake.createPdf(docDefinition).download('conversation-summary.pdf');
    };


    if (!currentUser) {
        return <Spinner text="Preparing Q&A session..."/>;
      }


    const lastMessageIndicatesEnd = messages.length > 0 && messages[messages.length - 1].content.includes("Thank you for engaging in this Q&A session");

    return (
        <S.ChatContainer>
            <S.TopActionContainer>
                <S.ChatHeader>Hi {currentUser?.username || 'User'}, welcome to the Q&A session</S.ChatHeader>
                <S.CancelButtonTop onClick={handleCancel}>
                    <S.CancelIcon viewBox="0 0 24 24">
                        <path d="M18 6L6 18M6 6l12 12"/>  {/* Cross icon */}
                    </S.CancelIcon>
                </S.CancelButtonTop>
            </S.TopActionContainer>
            <S.ChatBody>
                {messages.length > 0 ? (
                <>
                    {messages.map((message, index) => (
                        <S.ChatMessage key={index} role={message.role}>
                            {/* Check if the message is from the backend and needs formatting */}
                            {message.role === 'assistant' ? formatMessage(message.content) : message.content}
                        </S.ChatMessage>
                    ))}
                    {lastMessageIndicatesEnd && (
                        <S.ChatFooter>
                            <button onClick={generatePDF}>Download PDF</button>
                        </S.ChatFooter>
                    )}
                </>
            ) : (
            <div>The Q&A session has been ended</div>
                )}
            </S.ChatBody>
            <S.ChatFooter>
            <S.InputWrapper>
                <S.InputField 
                    ref={inputRef}
                    value={userInput} 
                    onChange={handleUserInput} 
                    onKeyDown={handleKeyDown}  
                    placeholder="Type your response..."
                    disabled={isLoading}
                />
                <S.SendButton 
                    onClick={handleSubmit} 
                    $isActive={!isLoading && userInput.trim() !== ''}
                    $isLoading={isLoading}
                    disabled={isLoading}
                >
                   
                <S.SendIcon viewBox="0 0 32 32" $isActive={!isLoading && userInput.trim() !== ''}>
                    {isLoading ? '...' : <path d="M2,2 L30,16 L2,30 L2,18 L24,16 L2,14 L2,2 Z"/>}
                </S.SendIcon>

                </S.SendButton>
            </S.InputWrapper>

            </S.ChatFooter>
        </S.ChatContainer>
    );
};

export default QAChatPage;