import styled from 'styled-components';

const breakpoints = {
  sm: '480px', // Small devices
  md: '768px', // Medium devices
  lg: '1024px', // Large devices
};

export const DashboardContainer = styled.div`
  margin: 10px;
  padding: 10px;
  max-width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

  @media (min-width: ${breakpoints.sm}) {
    max-width: 85%;
    padding: 15px;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 90%;
    padding: 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 95%;
    margin: 20px auto;
    padding: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${breakpoints.md}) {
    justify-content: space-between;
  }
`;

export const MenuItem = styled.li`
  flex: 0 0 calc(100% - 20px);
  margin: 10px auto;
  font-size: 18px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;

  @media (min-width: ${breakpoints.lg}) {
    flex: 0 0 calc(50% - 20px);
  }

  @media (min-width: ${breakpoints.lg}) {
    flex: 0 0 calc(33.333% - 20px);
  }

  a {
    text-decoration: none;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  }

  svg {
    margin-right: 10px;
  }

  span {
    color: #4caf50;
    font-weight: bold;
    margin-left: 10px;
  }
`;
