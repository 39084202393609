// // src/components/Navbar/Navbar.styles.js

// import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// const breakpoints = {
//   sm: '480px',
//   md: '768px',
//   lg: '1024px',
//   xl: '1200px'
// };

// export const NavContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 50px;
//   height: 80px;
//   background-color: #fff;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

//   @media (max-width: ${breakpoints.md}) {
//     padding: 0 30px;
//     height: 70px;
//   }

//   @media (max-width: ${breakpoints.sm}) {
//     padding: 0 20px;
//     height: 60px;
//   }
// `;

// export const BrandContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
// `;

// export const Brand = styled.h1`
//   color: #0056b3;
//   font-family: 'Poppins', sans-serif;
//   font-size: 2.5rem;
//   font-weight: 700;
//   letter-spacing: -1px;
//   margin: 0;
//   line-height: 1;
//   cursor: pointer;
//   transition: transform 0.3s ease-in-out, color 0.2s;

//   &:hover {
//     color: #023e8a;
//     transform: scale(1.02);
//   }

//   @media (max-width: ${breakpoints.md}) {
//     font-size: 2.2rem;
//   }

//   @media (max-width: ${breakpoints.sm}) {
//     font-size: 1.8rem;
//   }
// `;

// export const Logo = styled.img`
//   max-width: 24px;
//   max-height: 24px; 
//   transition: transform 0.3s ease-in-out;

//   @media (max-width: ${breakpoints.md}) {
//     max-width: 20px;
//     max-height: 20px;
//   }

//   @media (max-width: ${breakpoints.sm}) {
//     max-width: 18px;
//     max-height: 18px;
//   }

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// export const MenuToggle = styled.div`
//   display: none;
//   flex-direction: column;
//   cursor: pointer;

//   span {
//     height: 3px;
//     width: 25px;
//     background: #0056b3;
//     margin-bottom: 4px;
//     border-radius: 5px;
//   }

//   @media (max-width: ${breakpoints.md}) {
//     display: flex;
//   }
// `;

// export const NavLinks = styled.div`
//   display: flex;
//   align-items: center;
//   font-family: 'Poppins', sans-serif;
//   font-size: 1.2rem;
//   font-weight: 600;

//   @media (max-width: ${breakpoints.md}) {
//     display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
//     flex-direction: column;
//     align-items: flex-start;
//     position: absolute;
//     top: 70px;
//     left: 0;
//     right: 0;
//     background: #fff;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
//     padding: 10px 0;
//   }

//   @media (max-width: ${breakpoints.sm}) {
//     top: 60px;
//     font-size: 1rem;
//   }
// `;

// export const StyledLink = styled(Link)`
//   color: #212529;
//   margin: 0 20px;
//   padding: 8px 16px;
//   border-radius: 6px;
//   background-color: transparent;
//   transition: all 0.3s ease;
//   text-decoration: none;

//   &:hover {
//     color: #ffffff;
//     background-color: #007bff;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   }

//   @media (max-width: ${breakpoints.md}) {
//     margin: 5px 0;
//     padding: 6px 12px;
//     width: 100%;
//   }

//   @media (max-width: ${breakpoints.sm}) {
//     padding: 4px 10px;
//   }
// `;






// src/components/Navbar/Navbar.styles.js

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1200px'
};

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  height: 80px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: ${breakpoints.md}) {
    padding: 0 30px;
    height: 70px;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 0 20px;
    height: 60px;
  }
`;

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Brand = styled.h1`
  color: #0056b3;
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -1px;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.2s;

  &:hover {
    color: #023e8a;
    transform: scale(1.02);
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 2.2rem;
  }

  @media (max-width: ${breakpoints.sm}) {
    font-size: 1.8rem;
  }
`;

export const Logo = styled.img`
  max-width: 24px;
  max-height: 24px; 
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${breakpoints.md}) {
    max-width: 20px;
    max-height: 20px;
  }

  @media (max-width: ${breakpoints.sm}) {
    max-width: 18px;
    max-height: 18px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const MenuToggle = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 3px;
    width: 25px;
    background: #0056b3;
    margin-bottom: 4px;
    border-radius: 5px;
  }

  @media (max-width: ${breakpoints.md}) {
    display: flex;
  }
`;


export const NavLinks = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen', 
})`
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;

  @media (max-width: ${breakpoints.md}) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    top: 60px;
    font-size: 1rem;
  }
`;

export const StyledLink = styled(Link)`
  color: #212529;
  margin: 0 20px;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    background-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${breakpoints.md}) {
    margin: 5px 0;
    padding: 6px 12px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 4px 10px;
  }
`;


