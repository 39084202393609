import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import HomePage from '../pages/HomePage/HomePage';
import UserProfilePage from '../pages/UserProfilePage/UserProfilePage';
import ReportPage from '../pages/ReportPage/ReportPage';
import ActionPage from '../pages/ActionPage/ActionPage';
import Dashboard from '../components/Dashboard/Dashboard';
import Login from '../components/Login/Login';
import Registration from '../components/Registration/Registration';
import ResumeGenerator from '../components/ResumeGenerator/ResumeGenerator.jsx';
import CoverLetterCreator from '../components/CoverLetterCreator/CoverLetterCreator';
import MockInterview from '../components/MockInterview/MockInterview';
import ChatPage from '../pages/ChatPage/ChatPage';  
import QAChatPage from '../pages/QaChatPage/QAChatPage.jsx';
import EmailVerification from '../components/EmailVerification/EmailVerification';
import EmailConfirmationPage from '../components/EmailVerification/EmailConfirmationPage';
import ResetPasswordRequest from '../components/ResetPassword/ResetPasswordRequest.jsx';
import ResetPassword from '../components/ResetPassword/ResetPassword.jsx';
import GoogleLoginSuccess from '../components/GoogleLogin/GoogleLoginSuccess.jsx'
import PrivacyPolicy from '../components/Footer/PrivacyPolicy/PrivacyPolicy.jsx';
import  AboutUs from '../components/Footer/AboutUs/AboutUs.jsx';
import TermsOfService from '../components/Footer/TermsOfService/TermsOfService.jsx';
import Pricing from '../components/Footer/Pricing/Pricing.jsx';
import Faq from '../components/Footer/Faq/Faq.jsx';
import CreditBalance from '../components/CreditBalance/CreditBalance.jsx';



const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/google-login-success" element={<GoogleLoginSuccess />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/verify-email" element={<EmailVerification />} />
      <Route path="/email-confirmation" element={<EmailConfirmationPage />} />
      <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      } />
      <Route path="/creditbalance" element={
        <ProtectedRoute>
          <CreditBalance />
        </ProtectedRoute>
      } />
      <Route path="/profile" element={
        <ProtectedRoute>
          <UserProfilePage />
        </ProtectedRoute>
      } />
      <Route path="/interview" element={
        <ProtectedRoute>
          <MockInterview />
        </ProtectedRoute>
      } />
      <Route path="/chat" element={
        <ProtectedRoute>
          <ChatPage />  
        </ProtectedRoute>
      } />
      <Route path="/qa-chat" element={
        <ProtectedRoute>
          <QAChatPage />  
        </ProtectedRoute>
      } />
      <Route path="/report" element={
        <ProtectedRoute>
          <ReportPage />
        </ProtectedRoute>
      } />
       <Route path="/action" element={
        <ProtectedRoute>
          <ActionPage />
        </ProtectedRoute>
      } />
      <Route path="/resume-generator" element={
        <ProtectedRoute>
          <ResumeGenerator />
        </ProtectedRoute>
      } />
      <Route path="/cover-letter-creator" element={
        <ProtectedRoute>
          <CoverLetterCreator />
        </ProtectedRoute>
      } />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;










