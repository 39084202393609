import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from './assets/styles/globalStyles';
import { UserProvider } from './context/UserContext';
import { ResumeProvider } from './context/ResumeContext';
import { CoverLetterProvider } from './context/CoverLetterContext';
import AppRoutes from './routes/AppRoutes';
import Layout from './components/Layout/Layout';



// Create a QueryClient instance
const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GlobalStyles />
        <UserProvider>
          <ResumeProvider>
            <CoverLetterProvider>
              <Layout>
                <AppRoutes />
              </Layout>
            </CoverLetterProvider>
          </ResumeProvider>
        </UserProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
