// src/hooks/useRegisterUser.js
import { useMutation } from 'react-query';
import { registerUser } from '../utils/api'; // Ensure this is the correct import for your registerUser API function
import { useUser } from '../context/UserContext';

const useRegisterUser = () => {
  const { dispatch } = useUser();

  return useMutation(
    (userData) => registerUser(userData),
    {
      onSuccess: (user) => {
        console.log('Registration successful:', user);
        dispatch({ type: 'SET_CURRENT_USER', payload: user });
        // dispatch({ type: 'SET_LOADING', payload: false });

        // Optionally update the query cache if you are using it elsewhere
        // queryClient.setQueryData('user', user);
      },
      onError: (error) => {
        console.error('Registration error:', error);
        // dispatch({ type: 'SET_LOADING', payload: false });
      },
    }
  );
};

export default useRegisterUser;
