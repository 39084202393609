import styled from 'styled-components';
import { colors, fonts, spacing } from '../Common.styles';

export const PricingContainer = styled.div`
  background-color: ${colors.background};
  padding: ${spacing.sectionPadding};
  color: ${colors.primary};
  font-family: ${fonts.body};
  max-width: 800px;
  margin: 0 auto;
`;

export const PricingHeading = styled.h1`
  font-family: ${fonts.heading};
  font-size: 2rem;
  color: ${colors.accent};
  margin-bottom: 2rem;
  text-align: center;
`;

export const Section = styled.section`
  margin-bottom: ${spacing.sectionPadding};
`;

export const Subheading = styled.h2`
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  color: ${colors.secondary};
  margin: ${spacing.headingMargin} 0;
`;

export const Content = styled.p`
  font-size: 1rem;
  margin: ${spacing.contentMargin};
  line-height: 1.6;
  color: ${colors.primary};
`;

export const PriceDetails = styled.div`
  margin-top: 20px;
`;

export const CreditInfo = styled(Content)`
  font-weight: bold;
  color: ${colors.accent};
`;

export const CostBreakdown = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;


