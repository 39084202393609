import React, { createContext, useState, useContext } from 'react';
import { useUser } from './UserContext';
import { createResume,getErrorMessage} from '../utils/api.js';


const ResumeContext = createContext();

export const useResume = () => {
    return useContext(ResumeContext);
};

export const ResumeProvider = ({ children }) => {
    const { currentUser} = useUser();
    const [resume, setResume] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const [showErrorModal, setShowErrorModal] = useState(false);
    const [showPromotionModal, setShowPromotionModal] = useState(false);

    const generateResume = async (resumeData) => {
        setLoading(true);
        try {
            const generatedResume = await createResume(resumeData,currentUser.csrf_token,currentUser.access_token);
            setResume(generatedResume);
            setLoading(false);
            return generatedResume;
        } catch (err) {
            setLoading(false);
            const formattedError = getErrorMessage(err);
            // console.log(formattedError);
            setError(formattedError);
            setShowPromotionModal(true);
            return null;
        }
    };

    const clearResume = () => {
        setResume(null);
    };

    const clearError = () => {
        setError(null);
    };

    const contextValue = {
        resume,
        loading,
        error,
        showPromotionModal,
        generateResume,
        clearResume,
        clearError,
        setResume,
        setShowPromotionModal
    };

    return (
        <ResumeContext.Provider value={contextValue}>
            {children}
        </ResumeContext.Provider>
    );
};

// export default ResumeContext;
