import styled , { css }from 'styled-components';

export const UserProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
`;

export const SectionHeader = styled.h2`
  font-size: 1.5rem;
  color: #333;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export const InputField = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  flex-grow: 1;
  margin-bottom: 0.5rem; /* Added margin for spacing between inputs */
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

export const ToggleButton = styled.span`
  position: absolute;
  right: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover svg path {
    stroke: #007bff;
  }
`;


export const Error = styled.p`
  color: red;
  margin-top: 0.5rem;
`;

export const SuccessMessage = styled.p`
  color: green;
  margin-top: 0.5rem;
`;

// Shared button styles using css from styled-components for reusability
const buttonStyles = css`
  background-color: #009BFF;
  color: white;
  border: none;
  padding: 0.65rem 1.25rem;
  margin: 10px auto;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: #007BFF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const buttonWidth = '10rem'// Set the width of the button

// Using buttonStyles for SubmitButton
export const SubmitButton = styled.button`
  ${buttonStyles}
  width: ${buttonWidth};
`;

// Using buttonStyles for Button
export const Button = styled.button`
  ${buttonStyles}
  width: ${buttonWidth};
`;


