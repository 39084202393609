// Footer.jsx
import React from 'react';
import { FooterContainer, FooterContent, StyledLink, CopyrightText, LinksWrapper } from './Footer.styles'; // Updated imports

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LinksWrapper>
          <StyledLink to="/about-us">About Us</StyledLink>
          <StyledLink to="/pricing">Pricing</StyledLink>
          <StyledLink to="/faq">Help Center</StyledLink>
          <StyledLink to="/terms-of-service">Terms of Service</StyledLink>
          <StyledLink to="/privacy">Privacy Policy</StyledLink>
        </LinksWrapper>
        {/* Copyright text will now appear on the next line */}
        <CopyrightText>© 2024 PrepForJob, All Rights Reserved.</CopyrightText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
