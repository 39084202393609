import styled from 'styled-components';

export const CoverLetterContainer = styled.div`
  width: 80%;
  margin: 2em auto;
  padding: 1em;
  border: 1px solid #eaeaea;
  background-color: #fafafa;
`;

export const Title = styled.h1`
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const FileInput = styled.input`
  padding: 0.5em;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 1em;
`;

export const TextArea = styled.textarea`
  padding: 0.5em;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 150px;
  margin-bottom: 1em;
`;

export const SubmitButton = styled.button`
  padding: 0.8em 1em;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 0.8em;
  margin-top: 0.2em;
`;

export const Preview = styled.div`
  margin-top: 2em;
  padding: 1em;
  border: 1px solid #eaeaea;
  background-color: #f5f5f5;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

// New styles for loading spinner and messages
export const LoadingBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EnhancedSpinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid white;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingMessage = styled.p`
  color: white;
  margin-top: 16px;
  text-align: center;
`;



// New styles for the cover letter sections

export const CoverLetterSegment = styled.div`
  margin: 1.5em 0;
`;

export const CoverLetterHorizontalLine = styled.hr`
  border: 0.5px solid #eaeaea;
  margin: 1em 0;
`;

export const CoverLetterParagraph = styled.p`
  margin: 1em 0;
  text-align: justify;
  line-height: 1.5em;
`;

export const CoverLetterStrong = styled.strong`
  color: #007BFF;
  font-weight: bold;
`;

export const CoverLetterSignOff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 2em;
`;

export const CoverLetterNameContact = styled.p`
  margin: 0.5em 0;
`;


// In ResumeGenerator.styles.js
export const MockInterviewButton = styled.button`
  background-color: white; /* Start with a white background */
  color: #4caf50; /* Vibrant green for text for visibility */
  padding: 12px 30px; /* Larger padding for a bigger button */
  margin: 15px 0; /* Ensure it doesn't stick to other elements */
  border: 2px solid #4caf50; /* Green border to match text */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  font-size: 18px; /* Slightly larger font */
  font-weight: bold; /* Bold font for emphasis */
  transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: #4caf50; /* Vibrant green for background */
    color: white; /* White text for contrast */
    transform: scale(1.05); /* Slight increase in size */
    box-shadow: 0 2px 15px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Adjust font size for smaller devices */
    padding: 10px 20px; /* Adjust padding for smaller devices */
  }
`;


// In ResumeGenerator.styles.js
export const DownloadPDFButton = styled.button`
  background-color: #007bff; /* Primary color for the button */
  color: white;
  padding: 12px 30px;
  margin: 15px 0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: white; /* Reverse effect: background turns to white */
    color: #007bff; /* Text color changes to the original background color */
    border: 1px solid #007bff; /* Add border to maintain size */
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`;


// In ResumeGenerator.styles.js
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  align-items: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px; /* Additional space from the content above */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }
`;
