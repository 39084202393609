import styled from 'styled-components';

export const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

export const LoadingOverlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
`;

export const Spinner = styled.div`
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 5px solid black;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

export const LoadingText = styled.p`
    margin-top: 20px;
    font-size: 18px;
    color: #333;
`;

export const ReportContent = styled.div`
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`;

export const ReportHeader = styled.h1`
    font-size: 26px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    color: #0056b3;
    border-bottom: 2px solid #0056b3;
    padding-bottom: 10px;
`;

export const ReportSubHeader = styled.h2`
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #007BFF;
`;

export const ReportText = styled.p`
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
`;



export const FriendlyReminder = styled.p`
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
`;

export const StartInterviewButton = styled.button`
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

export const ReportDivider = styled.hr`
    width: 100%;
    border: 0;
    border-top: 1px solid #e1e1e1;
    margin: 20px 0;
`;

// Added error styles
export const ErrorContainer = styled.div`
    padding: 20px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin: 20px 0;
`;

export const ErrorMessage = styled.p`
    margin: 0;
    font-weight: bold;
`;



export const ButtonBase = styled.button`
    padding: 12px 25px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    margin: 10px; // Ensure some space between buttons if they wrap to a new line
    width: 200px; // You can adjust this value to fit your design

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }
`;

export const DownloadButton = styled(ButtonBase)`
    background-color: #007BFF;
    color: #fff;

    &:hover {
        background-color: #0056b3;
    }
`;

export const QuestionAnswerButton = styled(ButtonBase)`
    background-color: #28a745; // A green shade for emphasis
    color: #fff;

    &:hover {
        background-color: #218838; // A darker green on hover
    }
`;


// New styles for the Spinner Overlay when clicking QuestionAnswerButton
export const SpinnerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000; // Ensuring it is above everything else
`;
