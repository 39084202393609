import React, { useState, useEffect } from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useResume } from '../../context/ResumeContext';
import PromotionModal from "../../components/PromotionModal/PromotionModal";
import { sanitizeAndLimitInput} from '../../utils/api';
import { validateResume } from '../../utils/formValidation';
import * as S from './ResumeGenerator.styles';
import Spinner from '../../components/Spinner/Spinner';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


// Inside ResumeGenerator component




const ResumeGenerator = () => {
  
  const { generateResume, loading, error, showPromotionModal, resume, setResume,setShowPromotionModal } = useResume();
  const [formData, setFormData] = useState({
    experienceSummary: '',
    targetJobDescription: '',
  });
  const [errors, setErrors] = useState({});
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const { currentUser } = useUser();
  

  const navigate = useNavigate();

  const encouragingMessages = [
    "Relax, we're crafting your resume...",
    "Just a moment, making things perfect for you...",
    "Grab a cup of coffee, we're almost there...",
    "Thanks for waiting, it'll be worth it!"
  ];

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % encouragingMessages.length);
      }, 5000);  // change message every 5 seconds

      return () => clearInterval(interval); // clear the interval when the component unmounts or when loading is false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: sanitizeAndLimitInput(value),
    });
  };

  // console.log("Error", error);
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateResume(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await generateResume(formData);
      setResume(response);
    } catch (err) {
      console.error(err);
      // console.log("Before setShowErrorModal");
     
      // console.log("After setShowErrorModal");
    }
  };

  const handleMockInterviewClick = () => {
    navigate("/interview"); // Assuming '/mock-interview' is the route
  };

  const renderResumeText = (text) => {
    // Remove the `plaintext wrappers
    const cleanedText = text.replace(/^```plaintext|```$/g, '').trim();
    const lines = cleanedText.split('\n');
    let currentSection = "";

    const sectionHandlers = {
        "contact information": (line, index) => <S.ResumeText key={index}>{line}</S.ResumeText>,
        "summary": (line, index) => <S.ResumeText key={index}>{line}</S.ResumeText>,
        "experience": (line, index) => <S.ResumeText key={index}>{line}</S.ResumeText>,
        "skills": (line, index) => line.startsWith('-') ? <S.ResumeItem key={index}>{line}</S.ResumeItem> : <S.ResumeText key={index}>{line}</S.ResumeText>,
        "education & certifications": (line, index) => line.startsWith('-') ? <S.ResumeItem key={index}>{line}</S.ResumeItem> : <S.ResumeText key={index}>{line}</S.ResumeText>
    };

    return lines.map((line, index) => {
        // Handling the name explicitly on the first line
        if (index === 0) {
            return <S.ResumeHeader key={index}>{line.trim()}</S.ResumeHeader>;
        }

        if (["contact information", "summary", "experience", "skills", "education & certifications"].some(sec => line.toLowerCase().startsWith(sec + ":"))) {
            currentSection = line.split(":")[0].toLowerCase();
            return <S.ResumeSubHeader key={index}>{line.split(":")[0]}</S.ResumeSubHeader>;
        }
        
        if (currentSection && sectionHandlers[currentSection]) {
            return sectionHandlers[currentSection](line, index);
        } else if (line.trim() === "") {
            return <div key={index} style={{ marginBottom: '10px' }}></div>;
        } else {
            return <S.ResumeText key={index}>{line}</S.ResumeText>;
        }
    });
};







const generateResumePDF = (data) => {
  if (!data || !data.resume) {
      console.error("No resume data found.");
      return;
  }

  const resumeLines = data.resume.split('\n');
  const content = [];

  resumeLines.forEach((line, index) => {
      // Handling the name explicitly on the first line
      if (index === 0) {
          content.push({
              text: line.trim(),
              fontSize: 26,
              bold: true,
              alignment: 'center',
              margin: [0, 20, 0, 40],
              color: '#0056b3'
          });
      }
      else if (line.trim() === "") {
          // Divider for empty lines
          content.push({
              canvas: [{
                  type: 'line',
                  x1: 0, y1: 10,
                  x2: 515, y2: 10,
                  lineWidth: 1,
                  lineColor: '#e1e1e1'
              }],
              margin: [0, 20]
          });
      } 
      else if (["contact information", "summary", "experience", "skills", "education & certifications"].some(sec => line.toLowerCase().startsWith(sec + ":"))) {
          // Subheaders
          content.push({
              text: line.split(":")[0],
              fontSize: 22,
              color: '#007BFF',
              margin: [0, 30, 0, 15]
          });
      } 
      else if (line.startsWith('-')) {
          // Bullet points
          content.push({
              text: line,
              fontSize: 16,
              margin: [20, 2, 0, 2]
          });
      } 
      else {
          // Regular text
          content.push({
              text: line,
              fontSize: 16,
              margin: [0, 2]
          });
      }
  });

  const documentDefinition = {
      content: content,
      defaultStyle: {
          lineHeight: 1.5
      },
      pageSize: 'A4'
  };

  pdfMake.createPdf(documentDefinition).download("resume.pdf");
};




// we can build a loading spinner component that we use in multiple places




if (!currentUser) {
  return <Spinner text = "Preparing your resume generator..."/>;
}


return (
    <S.ResumeGeneratorContainer>
      <S.Title>Create Your Resume</S.Title>
      {showPromotionModal && (
        <PromotionModal 
          header="Insufficient Credit Balance"
          promotionMessage={error} 
          onClose={() => setShowPromotionModal(false)}
        />
      )}
      {/* {error && <S.Error>{error}</S.Error>} */}
      <S.Form onSubmit={handleSubmit}>
        <S.Section>
          <h2>Experience Summary</h2>
          <S.TextArea
            name="experienceSummary"
            placeholder={`Key Information:
              - Full Name
              - Contact Information (Phone, Email, LinkedIn, GitHub, etc.)
              - Experience (Companies, Positions, Timeframes)
              - Skills
              - Education & Certifications`}
            value={formData.experienceSummary}
            onChange={handleChange}
          />
          {errors.experienceSummary && <S.Error>{errors.experienceSummary}</S.Error>}
        </S.Section>
        <S.Section>
          <h2>Target Job Description</h2>
          <S.TextArea
            name="targetJobDescription"
            placeholder="Paste the job description..."
            value={formData.targetJobDescription}
            onChange={handleChange}
          />
          {errors.targetJobDescription && <S.Error>{errors.targetJobDescription}</S.Error>}
        </S.Section>
        <S.SubmitButton type="submit" disabled={loading}>
          Generate Resume
        </S.SubmitButton>
      </S.Form>
      {resume && (
        <S.ResumePreview>
          <div>
            {renderResumeText(resume.resume)}
          </div>
          <S.ButtonContainer>
            <S.DownloadPDFButton onClick={() => generateResumePDF(resume)}>
              Download as PDF
            </S.DownloadPDFButton>
            <S.MockInterviewButton onClick={() => handleMockInterviewClick()}>
              Ace Interviews
            </S.MockInterviewButton>
          </S.ButtonContainer>
        </S.ResumePreview>
      )}
      {loading && (
        <S.LoadingBackdrop>
          <S.SpinnerContainer>
            <S.EnhancedSpinner>
              <div></div>
              <div></div>
              <div></div>
            </S.EnhancedSpinner>
            <S.LoadingMessage>{encouragingMessages[currentMessageIndex]}</S.LoadingMessage>
          </S.SpinnerContainer>
        </S.LoadingBackdrop>
      )}
    </S.ResumeGeneratorContainer>
  );
};

export default ResumeGenerator;
