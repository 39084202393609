import styled from 'styled-components';

export const RegistrationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f4f4f4;
  position: relative;
`;

export const Form = styled.form`
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 300px;
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid ${props => (props.error ? '#ff4d4d' : '#ccc')};
  border-radius: 5px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const ToggleButton = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover svg path {
    stroke: #007bff;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background 0.2s;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:hover {
    background: ${props => (props.disabled ? '#ccc' : '#0056b3')};
  }
`;

export const Error = styled.p`
  color: #ff4d4d;
  margin-bottom: 10px;
`;
