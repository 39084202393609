// CustomModal.js
import React from 'react';
import styled from 'styled-components';


// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out, visibility 0.25s;
  ${({ isOpen }) => isOpen && `
    opacity: 1;
    visibility: visible;
  `}
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  transition: transform 0.25s ease-in-out;
  ${({ isOpen }) => isOpen && `
    transform: scale(1);
  `}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalBody = styled.div`
  margin-top: 20px;
`;

const Button = styled.button`
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }

  &.confirm {
    background-color: #007bff;
    color: white;
    &:hover {
      background-color: #0056b3;
    }
  }

  &.cancel {
    background-color: transparent;
    color: #333;
    &:hover {
      background-color: #f8f9fa;
    }
  }
`;

// export const Error = styled.p`
//   color: red;
//   margin-top: 0.5rem;
// `;

const ErrorMessage = styled.div`
  background-color: #f8d7da; // light red
  color: #721c24; // dark red
  padding: .75rem 1.25rem;
  margin-top: 1rem;
  border: 1px solid #f5c6cb; // border color
  border-radius: .25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
  max-height: ${({ show }) => (show ? '6rem' : '0')};
  overflow: hidden;
`;


// Add an onConfirm prop to AccountDeleteModal
const AccountDeleteModal = ({ isOpen, onClose, onConfirm, title, children, errorMessage }) => {
    return (
      <ModalOverlay isOpen={isOpen} onClick={onClose}>
        <ModalContent isOpen={isOpen} onClick={e => e.stopPropagation()}>
          <ModalHeader>
            <h2>{title}</h2>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          {errorMessage && <ErrorMessage show={!!errorMessage}>{errorMessage}</ErrorMessage>}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            {/* Call onConfirm when the confirm button is clicked */}
            <Button className="confirm" onClick={onConfirm}>Yes, Delete My Account</Button>
            <Button className="cancel" onClick={onClose}>Cancel</Button>
          </div>
        </ModalContent>
      </ModalOverlay>
    );
  };

export default AccountDeleteModal;
