import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import * as S from './Dashboard.styles';
import Spinner from '../Spinner/Spinner';

const Dashboard = () => {

  // useEffect(() => {
  //   localStorage.removeItem('retrieve');
  // }, []); // Runs once on component mount
  
  const { currentUser } = useUser();
  if (!currentUser) {
    return <Spinner text="Preparing your dashboard..."/>;
  }

  
  return (
    <S.DashboardContainer>
      <S.Header>
        <h1>Welcome, {currentUser?.username || 'Guest'}</h1>
      </S.Header>
      <S.Menu>
        <S.MenuItem>
          <Link to="/resume-generator">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <rect x="2" y="2" width="20" height="20" stroke="blue" fill="none"/>
              <path d="M 22,2 L 22,4 L 20,2 Z" stroke="blue" fill="none"/>
            </svg>
            Resume Generator
          </Link>
        </S.MenuItem>
        <S.MenuItem>
          <Link to="/cover-letter-creator">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M 2,4 L 22,4 L 22,20 L 2,20 Z" stroke="green" fill="none"/> 
              <path d="M 2,6 L 12,12 L 22,6" stroke="green" fill="none"/> 
            </svg>
            Cover Letter Creator
          </Link>
        </S.MenuItem>
        <S.MenuItem>
          <Link to="/interview">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="8" stroke="purple" fill="none"/>
              <ellipse cx="12" cy="18" rx="10" ry="5" stroke="purple" fill="none"/>
            </svg>
            Interview Simulation
          </Link>
        </S.MenuItem>
        <S.MenuItem>
          <Link to="/report">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <rect x="4" y="10" width="3" height="10" stroke="orange" fill="none"/>
              <rect x="8" y="6" width="3" height="14" stroke="orange" fill="none"/>
              <rect x="12" y="2" width="3" height="18" stroke="orange" fill="none"/>
              <rect x="16" y="8" width="3" height="12" stroke="orange" fill="none"/>
            </svg>
            Evaluation Report
          </Link>
        </S.MenuItem>
        <S.MenuItem>
          <Link to="/profile">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <circle cx="12" cy="8" r="5" stroke="red" fill="none"/>
              <rect x="9" y="15" width="6" height="7" stroke="red" fill="none"/>
            </svg>
            User Profile
          </Link>
        </S.MenuItem>
        <S.MenuItem>
          <Link to="/action">
            <svg width="24" height="24" viewBox="0 0 24 24">
              <rect x="4" y="4" width="16" height="16" stroke="cyan" fill="none"/>
              <rect x="6" y="6" width="12" height="3" stroke="cyan" fill="none"/>
              <rect x="6" y="10" width="12" height="3" stroke="cyan" fill="none"/>
              <rect x="6" y="14" width="12" height="3" stroke="cyan" fill="none"/>
            </svg>
            Action Plan
          </Link>
        </S.MenuItem>
      </S.Menu>
    </S.DashboardContainer>
  );
};

export default Dashboard;
