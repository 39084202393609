// styles/Common.styles.js
import styled from 'styled-components';

export const colors = {
  primary: '#333',
  secondary: '#555',
  accent: '#007bff',
  background: '#f9f9f9',
  light: '#ffffff',
};

export const fonts = {
  heading: 'Arial, sans-serif',
  body: 'Georgia, serif',
};

export const spacing = {
  sectionPadding: '20px',
  contentMargin: '10px 0',
  headingMargin: '15px 0',
};

export const Container = styled.div`
  background-color: ${colors.background};
  padding: ${spacing.sectionPadding};
  color: ${colors.primary};
  font-family: ${fonts.body};
`;

export const Heading = styled.h1`
  font-family: ${fonts.heading};
  font-size: 2rem;
  color: ${colors.accent};
  margin-bottom: ${spacing.headingMargin};
`;

export const Subheading = styled.h2`
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  color: ${colors.secondary};
  margin: ${spacing.headingMargin} 0;
`;

export const Content = styled.p`
  font-size: 1rem;
  margin: ${spacing.contentMargin};
  line-height: 1.6;
  color: ${colors.primary};
`;

export const Section = styled.section`
  margin-bottom: ${spacing.sectionPadding};
`;

export const UnorderedList = styled.ul`
  margin: ${spacing.contentMargin};
  padding-left: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;

export const ExternalLink = styled.a`
  color: ${colors.accent};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Highlight = styled.span`
  color: ${colors.accent};
  font-weight: bold;
`;




