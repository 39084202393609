import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as S from './ActionPage.styles';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ActionPage = () => {
    const { currentUser } = useUser();
    const [actionData, setActionData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const navigate = useNavigate();

    useEffect(() => {
        const fetchAction = async () => {
            if (!currentUser?.username) {
                return;
              }  
            try {
                const response = await fetch(`${BASE_URL}/api/v1/action/`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${currentUser ? currentUser.access_token : ''}`,
                        'X-CSRF-Token': currentUser?.csrf_token,
                        'Content-Type': 'application/json',
                        'username': currentUser?.username
                    }
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.detail || "Error fetching action plan.");
                }
                const data = await response.json();
                setActionData(data.action);
            } catch (error) {
                console.error("Error fetching action:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAction();
    }, [currentUser, BASE_URL]);

    



    const renderReportText = (text) => {
        const lines = text.split('\n');
        let currentSection = "";
        
        const sectionHandlers = {
            "action plan for": (line, index) => <S.ReportHeader key={`header-${index}`}>{line.trim()}</S.ReportHeader>,
            "1. skill development": (line, index) => <S.ReportSubHeader key={`subheader-skill-${index}`}>{line}</S.ReportSubHeader>,
            "2. personal development": (line, index) => <S.ReportSubHeader key={`subheader-pd-${index}`}>{line}</S.ReportSubHeader>,
            "3. study tips": (line, index) => <S.ReportSubHeader key={`subheader-study-${index}`}>{line}</S.ReportSubHeader>,
            "4. recommended job opportunities": (line, index) => <S.ReportSubHeader key={`subheader-job-${index}`}>{line}</S.ReportSubHeader>,
            "5. interview tips": (line, index) => <S.ReportSubHeader key={`subheader-interview-${index}`}>{line}</S.ReportSubHeader>,
            "6. networking": (line, index) => <S.ReportSubHeader key={`subheader-net-${index}`}>{line}</S.ReportSubHeader>,
            "7. course recommendation": (line, index) => <S.ReportSubHeader key={`subheader-course-${index}`}>{line}</S.ReportSubHeader>,
            "8. online resources": (line, index) => <S.ReportSubHeader key={`subheader-online-${index}`}>{line}</S.ReportSubHeader>
        };
        
        return lines.map((line, index) => {
            // Check for "Action Plan for ..." header
            if (line.toLowerCase().startsWith("action plan for")) {
                currentSection = "action plan for";
                return sectionHandlers[currentSection](line, index);
            }
            
            // Check for other subheaders
            if (["1. skill development", "2. personal development", "3. study tips", "4. recommended job opportunities", "5. interview tips", "6. networking", "7. course recommendation", "8. online resources"].some(sec => line.toLowerCase().startsWith(sec))) {
                currentSection = line.toLowerCase();
                if (sectionHandlers[currentSection]) {
                    return sectionHandlers[currentSection](line, index);
                }
            } 
            
            // Avoid rendering the ReportDivider right after the main header
            if (line.trim() === "" && lines[index - 1] && lines[index - 1].toLowerCase().startsWith("action plan for")) {
                return null;
            }
            
            // Add dividers for empty lines between sections, but not after headers
            if (line.trim() === "" && index > 0 && lines[index - 1].trim() !== "") {
                return <S.ReportDivider key={`divider-${index}`} />;
            } else {
                return <S.ReportText key={`text-${index}`}>{line}</S.ReportText>;
            }
        });
    };
    
    
    

    


    const generateReportPDF = () => {
        const actionSections = actionData.split('\n');
        const content = [];
        
        actionSections.forEach((line, index) => {
            if (line.trim().toLowerCase().startsWith("action plan for")) {
                // Top Header
                content.push({
                    text: line.trim(),
                    fontSize: 26,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 20, 0, 40],
                    color: '#0056b3'
                });
            } else if (line.trim() === "") {
                // Divider
                content.push({
                    canvas: [{
                        type: 'line',
                        x1: 0, y1: 10,
                        x2: 515, y2: 10,
                        lineWidth: 1,
                        lineColor: '#e1e1e1'
                    }],
                    margin: [0, 20]
                });
            } else if (["1. skill development", "2. personal development", "3. study tips", "4. recommended job opportunities", "5. interview tips", "6. networking", "7. course recommendation", "8. online resources"].some(sec => line.toLowerCase().startsWith(sec))) {
                // Subheaders
                content.push({
                    text: line,
                    fontSize: 22,
                    color: '#007BFF',
                    margin: [0, 30, 0, 15]
                });
            } else {
                // Regular text
                content.push({
                    text: line,
                    fontSize: 16,
                    margin: [0, 2]
                });
            }
        });
        
        const documentDefinition = {
            content: content,
            defaultStyle: {
                lineHeight: 1.5
            },
            pageSize: 'A4'
        };
        
        pdfMake.createPdf(documentDefinition).download("action_plan.pdf");
    };
    



    return (
        <S.ReportContainer>
            {isLoading ? (
                <S.LoadingOverlay>
                    <S.Spinner />
                    <S.LoadingText>Action plan is being generated, please wait…</S.LoadingText>
                </S.LoadingOverlay>
            ) : (
                <>
                    {actionData && actionData.startsWith("Please participate in the interview") ? (
                        <>
                            <S.FriendlyReminder>{actionData}</S.FriendlyReminder>
                            <S.StartInterviewButton onClick={() => navigate('/interview')}>Start Interview Now</S.StartInterviewButton>
                        </>
                    ) : error ? (
                        <S.ErrorContainer>
                            <S.ErrorMessage>{error}</S.ErrorMessage>
                        </S.ErrorContainer>
                    ): (
                        <>
                            <S.ReportContent>
                                {renderReportText(actionData)}
                            </S.ReportContent>
                            <S.DownloadButton onClick={generateReportPDF}>
                                Download as PDF
                            </S.DownloadButton>
                        </>
                    )}
                </>
            )}
        </S.ReportContainer>
    );
};

export default ActionPage;








