import styled from 'styled-components';
import { colors, fonts, spacing } from '../Common.styles';

export const AboutContainer = styled.div`
  background-color: ${colors.background};
  padding: ${spacing.sectionPadding};
  color: ${colors.primary};
  font-family: ${fonts.body};
  max-width: 800px;
  margin: 0 auto;
  

`;

export const Heading = styled.h1`
  font-family: ${fonts.heading};
  font-size: 2rem;
  color: ${colors.accent};
  margin-bottom: 2rem;
  text-align: center;
`;

export const Subheading = styled.h2`
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  color: ${colors.secondary};
  margin: ${spacing.headingMargin} 0;
`;

export const Content = styled.p`
  font-size: 1rem;
  margin: ${spacing.contentMargin};
  line-height: 1.6;
  color: ${colors.primary};
`;

export const Section = styled.section`
  margin-bottom: ${spacing.sectionPadding};
`;

export const UnorderedList = styled.ul`
  margin: ${spacing.contentMargin};
  padding-left: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;

export const ContactInfo = styled.div`
  margin-top: 20px;
  font-style: italic;
`;

export const ContactEmail = styled.a`
  color: ${colors.accent};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const Address = styled.div`
  margin-top: 10px;
  color: ${colors.secondary};
`;

export const Highlight = styled.span`
  color: ${colors.accent};
  font-weight: bold;
`;
