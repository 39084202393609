// new version of MockInterview.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { sanitizeAndLimitInput, pdfToText, getErrorMessage } from '../../utils/api';
import { validateInterview } from '../../utils/formValidation';
// import ErrorModal from "../../components/ErrorModal/ErrorModal";
import * as S from './MockInterview.styles';
import Spinner from '../../components/Spinner/Spinner';
import PromotionModal from "../../components/PromotionModal/PromotionModal";

const MockInterview = () => {
    const [formData, setFormData] = useState({
        resume: '',
        targetJobDescription: '',
        difficultyLevel: ''
    });
    const [errors, setErrors] = useState({});
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [showErrorModal, setShowErrorModal] = useState(false);
    const [showPromotionModal, setShowPromotionModal] = useState(false);
    const navigate = useNavigate();
    const { currentUser } = useUser();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    // console.log(currentUser);

    const handleFileChange = async (e, field) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const parsedText = await pdfToText(event.target.result);
                setFormData(prevData => ({
                    ...prevData,
                    [field]: sanitizeAndLimitInput(parsedText)
                }));
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: sanitizeAndLimitInput(value)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const validationErrors = validateInterview(formData);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/summary/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'X-CSRF-Token': currentUser.csrf_token,
                        'Authorization': `Bearer ${currentUser.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        resume: formData.resume,
                        target_job_description: formData.targetJobDescription,
                        difficultyLevel: formData.difficultyLevel,
                        username: currentUser.username
                    })
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.detail || 'Unknown error');
                }
                setIsLoading(false);
                if (response.ok && responseData && responseData.message) {
                    navigate('/chat', {
                        state: {
                            initialMessage: responseData.message
                        }
                    });
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
                const formattedError = getErrorMessage(error);
                console.log(formattedError);
                setError(formattedError);
                console.log(error);
                setShowPromotionModal(true);
                console.error("Error submitting interview data:", error);
            }
        } else {
            setIsLoading(false);
            setErrors(validationErrors);
        }
    };
    

    if (!currentUser) {
        return <Spinner text = "Preparing your mock interview page..."/>;
      }

    return (
        <S.MockInterviewContainer>
            {isLoading && (
                <S.LoadingOverlay>
                    <S.Spinner />
                    <S.LoadingText>Preparing your interview... Please wait.</S.LoadingText>
                </S.LoadingOverlay>
            )}
            
            <S.Title>Welcome to the AI-powered mock interview!</S.Title>
            {showPromotionModal && (
                <PromotionModal 
                header="Insufficient Credit Balance"
                promotionMessage={error} 
                onClose={() => setShowPromotionModal(false)}
                />
            )}
            <S.Introduction>
                Here, you'll be able to simulate a real interview experience. Make sure you've prepared your resume and cover letter as they will be evaluated during this simulation.
            </S.Introduction>
            
            <S.Form onSubmit={handleSubmit}>
                <label>Upload or Paste your resume:</label>
                <S.FileInputContainer>
                    <S.FileInputLabel htmlFor="resumeFile">
                        Upload Resume
                        {formData.resume && <S.FileName>{formData.resume.name}</S.FileName>}
                    </S.FileInputLabel>
                    <S.FileInput 
                        id="resumeFile" 
                        type="file" 
                        accept=".pdf" 
                        onChange={(e) => handleFileChange(e, 'resume')} 
                    />
                </S.FileInputContainer>
                <S.TextArea 
                    name="resume"
                    placeholder="Or, paste your Resume here"
                    value={formData.resume}
                    onChange={handleChange}
                />
                {errors.resume && <S.Error>{errors.resume}</S.Error>}

                <label>Upload or Paste target job description:</label>
                <S.FileInputContainer>
                    <S.FileInputLabel htmlFor="targetJobDescriptionFile">
                        Upload Target Job Description
                        {formData.targetJobDescription && <S.FileName>{formData.targetJobDescription.name}</S.FileName>}
                    </S.FileInputLabel>
                    <S.FileInput 
                        id="targetJobDescriptionFile" 
                        type="file" 
                        accept=".pdf" 
                        onChange={(e) => handleFileChange(e, 'targetJobDescription')} 
                    />
                </S.FileInputContainer>
                <S.TextArea 
                    name="targetJobDescription"
                    placeholder="Or, paste Target Job Description here"
                    value={formData.targetJobDescription}
                    onChange={handleChange}
                />
                {errors.targetJobDescription && <S.Error>{errors.targetJobDescription}</S.Error>}

                <label>Select the difficulty level:</label>
                <S.SelectField
                    name="difficultyLevel"
                    value={formData.difficultyLevel}
                    onChange={handleChange}
                >
                    <option value="">Select Difficulty Level</option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                </S.SelectField>
                {errors.difficultyLevel && <S.Error>{errors.difficultyLevel}</S.Error>}

                <S.SubmitButton type="submit" disabled={isLoading}>Start Interview</S.SubmitButton>
            </S.Form>
        </S.MockInterviewContainer>
    );
};

export default MockInterview;