import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useUser } from '../../context/UserContext'; // Adjust the import path as necessary

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingSpinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

const GoogleLoginSuccess = () => {
  const navigate = useNavigate();
  const { dispatch,isAuthenticated } = useUser();
  const [readyToRedirect, setReadyToRedirect] = useState(false)
  

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries());
    // Dispatch action to update user state
    dispatch({ type: 'SET_CURRENT_USER', payload: params });
  // After updating user, dispatch success action
    dispatch({ type: 'SET_USER_AUTH_SUCCESS', payload: true });
    // Set a timeout before redirecting
    const timer = setTimeout(() => {
        setReadyToRedirect(true);
      }, 0); // 1-second delay
  
      // Cleanup the timer
      return () => clearTimeout(timer);
  }, [dispatch]);


  useEffect(() => {
    if (isAuthenticated && readyToRedirect) {
      localStorage.setItem("isAuthenticated", "true");
      navigate('/dashboard');
    }
  }, [isAuthenticated, readyToRedirect, navigate]);

  return (
    <SpinnerContainer>
      <LoadingSpinner />
    </SpinnerContainer>
  );
};

export default GoogleLoginSuccess;
