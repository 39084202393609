import React, { useState } from 'react';
import styled ,{ css , createGlobalStyle} from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';


// Global style for theme colors and fonts
const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #007bff;
    --hover-primary-color: #0056b3;
    --secondary-color: #6c757d;
    --hover-secondary-color: #5a6268;
    --background-color: #fff;
    --text-color: #333;
    --border-radius: 6px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

const ModalContent = styled.div`
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: auto;
  max-width: 500px; // Responsive width
  font-family: var(--font-family);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px; // Spacing between form elements
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
  color: var(--text-color);
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  &:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s, box-shadow 0.2s;

  &:hover {
    background-color: var(--hover-primary-color);
    transform: translateY(-2px);
    box-shadow: var(--box-shadow);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
  }

  ${props => props.cancel && css`
    background-color: var(--secondary-color);
    &:hover {
      background-color: var(--hover-secondary-color);
    }
  `}
`;

const ErrorMessage = styled.div`
  color: #d9534f; // Changed to a more standard error color
  margin-top: 10px;
  font-weight: bold; // Make error message stand out
`;


const TopUpModal = ({ isOpen, onClose, stripePromise, onPaymentSuccess, BASE_URL }) => {
  const [credits, setCredits] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [error, setError] = useState('');
  const calculateUSD = (credits) => {
    // Assuming 1 credit = 0.01 US cents
    return credits / 100;
  };

// Resets the state to initial values to ensure fresh start
const resetState = () => {
    setCredits('');
    setShowCheckout(false);
  };


  const validateTopUp = (credits) => {
    // Convert the input to a numeric value safely
    const creditsNumeric = Number(credits);

    if (isNaN( creditsNumeric) ||  creditsNumeric <= 0) {
      return 'Please enter a valid number of credits';
    }
    
    // Calculate the total amount in US cents from the given credits
    const totalCents = creditsNumeric * 0.01; // Since 1 credit = 0.01 US cents
  
    // Check for minimum amount in US cents
    if (totalCents < 50) {
      return "Minimum top-up amount is 50 US cents.";
    }

    // Check if the total amount in US cents is a whole number
    if (!Number.isInteger(totalCents)) {
      return "Top-up amount must be a whole number in US cents.";
    }
  
    // If both conditions are satisfied, return an empty string indicating no error
    return "";
  };
  
  

  const handleSubmit = (e) => {
    e.preventDefault();
    // const numericCredits = parseFloat(credits);
    // if (isNaN(numericCredits) || numericCredits <= 0) {
    //   alert('Please enter a valid number of credits');
    //   return;
    // }

    // Call the validateTopUp function with the credits entered by the user
  const validationMessage = validateTopUp(credits);
  
  // Check if there was a validation error message returned
  if (validationMessage) {
    // If so, display the validation error message to the user and halt the submission
    // alert(validationMessage);
    setError(validationMessage)
    return;
  }
    setShowCheckout(true); // Show the CheckoutForm for payment processing
  };

  const handleCreditsChange = (e) => {
    setCredits(e.target.value);
  };

  // Call resetState when closing the modal to ensure a fresh start
  const handleClose = () => {
    resetState();
    onClose(); // Call the passed onClose to handle any additional closing logic
  };

//   const calculatedAmount = calculateUSD(credits);

  // Handler for clicks on the overlay
  const handleOverlayClick = (event) => {
    // Check if the click was on the overlay itself and not its children
    if (event.target === event.currentTarget) {
      onClose(); // Close the modal
    }
  };




  return isOpen ? (
    <>
    <GlobalStyle />
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent onClick={e => e.stopPropagation()}>
        {!showCheckout ? (
          <Form onSubmit={handleSubmit}>
            <Label htmlFor="amount">Credits to Purchase:</Label>
            <Input
              id="credits"
              type="number"
              value={credits}
              onChange={handleCreditsChange}
            />
            <p>Estimated Cost: {calculateUSD(credits)} US cents</p>
            <ButtonContainer>
            <Button type="submit">Proceed to Payment</Button>
            <Button type="button" onClick={handleClose} cancel>Cancel</Button>
          </ButtonContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          </Form>
        ) : (
          <Elements stripe={stripePromise}>
            <CheckoutForm 
              credits={credits} 
              calculateUSD={calculateUSD}
              onPaymentSuccess={() => {
                onPaymentSuccess();
                resetState(); // Also reset the state after successful payment
              }}  
              BASE_URL={BASE_URL} 
              onClose={handleClose} // Use handleClose here as well
            />
          </Elements>
        )}
      </ModalContent>
    </ModalOverlay>
    </>
  ) : null;
};

export default TopUpModal;
